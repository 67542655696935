import api from "../apiConfig";
import paramsFactory from "../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchReport(reportName, params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/report/${reportName}?${p}`);
  },
  exportDepartmentTicketsSummaryToExcel(report, params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/report/${report}?${p}`, {
      responseType: "blob",
    });
  },
};
