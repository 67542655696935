import { useState } from "react";
import Typography from "@mui/material/Typography";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import { BiFontSize, BiLogOutCircle, BiUserCircle } from "react-icons/bi";
import { GoDeviceMobile } from "react-icons/go";
import {
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Avatar,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "../../../app/slices/authSlice";
import { TbSignature } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import OneSignal from "react-onesignal";

const ProfileMenu = () => {
  const { user } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
    <div>
      <ListItemButton dense onClick={handleMenu}>
        <ListItemAvatar>
          {user.info.profile_pic ? (
            <Avatar src={user.info.profile_pic}></Avatar>
          ) : (
            <Avatar>
              <AccountCircle />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              variant="h6"
              fontWeight={"bold"}
              sx={{ fontSize: { xs: "0.75rem", sm: "1.25rem" } }}
            >
              {user.info?.full_name}
            </Typography>
          }
          secondary={
            <Typography
              variant="caption"
              color="wheat"
              sx={{ fontSize: { xs: "0.62rem", sm: "0.75rem" } }}
            >
              {user.info.department_name}
            </Typography>
          }
        />
      </ListItemButton>
      <Menu
        sx={{ mt: "55px" }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* 
        <MenuItem
          onClick={() => {
            window.open("https://signature.halasat.com/", "_blank");
            handleClose();
          }}
        >
          <ListItemIcon>
            <TbSignature />
          </ListItemIcon>
          <ListItemText primary="أنشاء توقيع" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(
              "https://hrms-api.halasat.com/storage/downloads/hrms.apk",
              "_blank"
            );
            handleClose();
          }}
        >
          <ListItemIcon>
            <GoDeviceMobile />
          </ListItemIcon>
          <ListItemText primary="تحميل التطبيق" />
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            navigate("/profile");
            handleClose();
          }}
        >
          <ListItemIcon>
            <BiUserCircle />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            dispatch(Auth.logout());
          }}
        >
          <ListItemIcon>
            <BiLogOutCircle />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
