import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";

const clientsApi = factory.get("clients");

const initialState = {
  clients: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
      is_active: null,
      is_contracted: null,
      deleted: null,
    },
  },
  client_profile: {
    profile: {
      id: "",
      name: "",
      full_name: "",
      email: "",
      password: "",
      phone_1: "",
      phone_2: "",
      note: "",
      is_contracted: false,
    },
    statistics: {
      count_ats: 0,
      count_ticket: 0,
      count_order: 0,
      count_child_ats: 0,
    },
    ats: [],
    loading: false,
  },
  manage_client: {
    form: {
      id: "",
      name: "",
      full_name: "",
      email: "",
      password: "",
      phone_1: "",
      phone_2: "",
      notes: "",
      is_contracted: "false",
      attachment: [],
      preview_attachment: [],
    },
    is_home: true,
    dialog: false,
    loading: false,
    errors: {},
  },
  tree_towers: {
    nodes: [],
    edges: [],
    loading: false,
    is_tree_towers_page: false,
  },
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setClients: (state, action) => {
      state.clients.data = action.payload.data.data;
      state.clients.total = action.payload.data.total;
    },
    setClientProfile: (state, action) => {
      state.client_profile.profile = action.payload.profile.data[0];
      state.client_profile.ats = action.payload.profile.data[0].ats;
      state.client_profile.statistics = {
        ...action.payload.statistics.data,
      };
      state.client_profile.loading = false;
    },
    resetForm: (state, action) => {
      state.manage_client.form = initialState.manage_client.form;
    },
    setFilters: (state, { payload }) => {
      state.clients.filters = {
        ...state.clients.filters,
        ...payload,
      };
    },
    setDialog: (state, { payload }) => {
      if (payload.data) {
        state.manage_client.form.id = payload.data.id;
        state.manage_client.form.name = payload.data.name;
        state.manage_client.form.full_name = payload.data.full_name;
        state.manage_client.form.email = payload.data.email;
        state.manage_client.form.phone_1 = payload.data.phone_1;
        state.manage_client.form.phone_2 = payload.data.phone_2;
        state.manage_client.form.notes = payload.data.note;
        state.manage_client.form.is_contracted = payload.data.is_contracted
          ? "true"
          : "false";
        // state.manage_client.form.attachment = payload.data.attachment;
        state.manage_client.form.preview_attachment = payload.data.attachment;

        state.manage_client.is_home = payload.is_home;
      } else state.manage_client.form = initialState.manage_client.form;
      state.manage_client.dialog = !state.manage_client.dialog;
    },
    resetFilters: (state) => {
      state.clients.filters = initialState.clients.filters;
    },
    setTreeTowers: (state, { payload }) => {
      state.tree_towers.nodes = [];
      state.tree_towers.edges = [];
      payload.map((item) => {
        state.tree_towers.nodes.push({
          id: item.id,
          type: "custom",
          data: {
            label: item.name,
            ip: item.ip,
            is_selected: item.is_selected,
          },
          position: { x: 0, y: 0 },
        });
      });
      state.tree_towers.edges = payload;
      state.tree_towers.loading = false;
    },
    setIsTreeTowersPage: (state, { payload }) => {
      if (payload !== undefined) {
        state.tree_towers.is_tree_towers_page = payload;
      } else {
        state.tree_towers.is_tree_towers_page =
          !state.tree_towers.is_tree_towers_page;
      }
    },
  },
});

export const {
  setLoading,
  setClients,
  setClient,
  setClientProfile,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setTreeTowers,
  setIsTreeTowersPage,
} = clientsSlice.actions;

export default clientsSlice.reducer;

//axios
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().clients.clients.filters;
    dispatch(setLoading("clients"));
    const res = await clientsApi.index(filters);
    dispatch(setClients(res));
    dispatch(setLoading("clients"));
  } catch (err) {
    dispatch(setLoading("clients"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const showById = (id) => async (dispatch, getState) => {
  try {
    dispatch(setIsTreeTowersPage(false));
    dispatch(setLoading("client_profile"));
    const res_profile = await clientsApi.showById(id);
    const res_statistics = await clientsApi.statistics(id);
    dispatch(
      setClientProfile({
        profile: res_profile.data,
        statistics: res_statistics.data,
      })
    );
    dispatch(setLoading("tree_towers"));
    const res_tree = await clientsApi.treeTowers(id);
    dispatch(setTreeTowers(res_tree.data));
  } catch (err) {
    dispatch(setLoading("client_profile"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("manage_client"));
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "attachment") {
        if (data.attachment.length > 0) {
          data.attachment.forEach((item, index) => {
            formData.append(`attachment[${index}]`, item);
          });
        }
      } else {
        formData.append(key, data[key]);
      }
    });
    await clientsApi.create(formData);
    dispatch(setLoading("manage_client"));
    dispatch(
      showNotification({
        type: "success",
        message: "Client created successfully",
      })
    );
    dispatch(setDialog({ data: null }));
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manage_client"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors,
      })
    );
    throw new Error(err);
  }
};
const update = (data) => async (dispatch, getState) => {
  try {
    const { id } = getState().clients.manage_client.form;
    const { is_home } = getState().clients.manage_client;
    delete data.password;
    delete data.preview_attachment;

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "attachment") {
        if (data.attachment.length > 0) {
          data.attachment.forEach((item, index) => {
            formData.append(`attachment[${index}]`, item);
          });
        }
      } else {
        formData.append(key, data[key]);
      }
    });
    formData.append("_method", "PUT");
    dispatch(setLoading("manage_client"));
    await clientsApi.update(id, formData);
    dispatch(setLoading("manage_client"));
    dispatch(
      showNotification({
        type: "success",
        message: "Client updated successfully",
      })
    );
    if (is_home) {
      dispatch(index());
    } else {
      dispatch(showById(id));
    }
    dispatch(setDialog({ data: null }));
    dispatch(resetForm());
  } catch (err) {
    dispatch(setLoading("manage_client"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors,
      })
    );
    // throw new Error(err);
  }
};
const changeStatus = (id, status) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("clients"));
    await clientsApi.changeStatus(id, status);
    dispatch(setLoading("clients"));
    dispatch(
      showNotification({
        type: "success",
        message: "Client status changed successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("clients"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const changePassword = (id, data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("clients"));
    await clientsApi.changePassword({
      new_password: data,
      client_id: id,
    });
    dispatch(setLoading("clients"));
    dispatch(
      showNotification({
        type: "success",
        message: "Client Password changed successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("clients"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const verify = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("client_profile"));
    await clientsApi.verified(id);
    dispatch(setLoading("client_profile"));
    dispatch(
      showNotification({
        type: "success",
        message: "Client Verified successfully",
      })
    );
    dispatch(showById(id));
  } catch (err) {
    dispatch(setLoading("client_profile"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const removeVerification = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("client_profile"));
    await clientsApi.unVerified(id);
    dispatch(setLoading("client_profile"));
    dispatch(
      showNotification({
        type: "success",
        message: "Client verification removed successfully",
      })
    );
    dispatch(showById(id));
  } catch (err) {
    dispatch(setLoading("client_profile"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const removeClient = (id) => async (dispatch, getState) => {
  try {
    await clientsApi.removeClient(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Client deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const restoreClient = (ClientId) => async (dispatch, getState) => {
  try {
    await clientsApi.restoreClient(ClientId);
    dispatch(
      showNotification({
        type: "success",
        message: "Client restored successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
  }
};
export const ClientsCall = {
  index,
  showById,
  create,
  update,
  changeStatus,
  changePassword,
  removeVerification,
  verify,
  removeClient,
  restoreClient,
};
