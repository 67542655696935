import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";

const rolesPermissionsApi = factory.get("roles_permissions");

const initialState = {
  roles: {
    loading: false,
    data: [],
  },
  permissions: {
    loading: false,
    data: [],
  },
  role: {
    loading: false,
    form: {
      id: "",
      name: "",
      permissions: [],
    },
    dialog: false,
    errors: {},
  },
  client_profile: {
    profile: {
      id: "",
      name: "",
      full_name: "",
      email: "",
      password: "",
      phone_1: "",
      phone_2: "",
      note: "",
    },
    statistics: {
      count_ats: 0,
      count_ticket: 0,
      count_order: 0,
      count_child_ats: 0,
    },
    ats: [],
    loading: false,
  },
  manage_client: {
    form: {
      id: "",
      name: "",
      full_name: "",
      email: "",
      password: "",
      phone_1: "",
      phone_2: "",
      note: "",
    },
    is_home: true,
    dialog: false,
    loading: false,
    errors: {},
  },
  tree_towers: {
    nodes: [],
    edges: [],
    loading: false,
    is_tree_towers_page: false,
  },
};

export const rolesPermissionsSlice = createSlice({
  name: "rolesPermissions",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setRoles: (state, action) => {
      state.roles.data = action.payload.data.data;
    },

    setPermissions: (state, action) => {
      state.permissions.data = action.payload.data.data;
    },
    setClient: (state, action) => {
      const { id, name, full_name, email, phone_1, phone_2, notes } =
        action.payload.data[0];
      state.manage_client.form = {
        id,
        name,
        full_name,
        email,
        phone_1,
        phone_2,
        notes,
        password: "",
      };
      state.manage_client.ats = action.payload.data[0].ats;
    },
    setClientProfile: (state, action) => {
      state.client_profile.profile = action.payload.profile.data[0];
      state.client_profile.ats = action.payload.profile.data[0].ats;
      state.client_profile.statistics = {
        ...action.payload.statistics.data,
      };
      state.client_profile.loading = false;
    },
    resetForm: (state, action) => {
      state.role.form = initialState.role.form;
    },
    setFilters: (state, { payload }) => {
      state.clients.filters = {
        ...state.clients.filters,
        ...payload,
      };
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.role.form.id = payload.id;
        state.role.form.name = payload.name;
        if (payload.permissions.length > 0) {
          payload.permissions.map((item) => {
            state.role.form.permissions.push(item.name);
          });
        } else {
          state.role.form.permissions = [];
        }
      } else state.role.form = initialState.role.form;
      state.role.dialog = !state.role.dialog;
    },
    resetFilters: (state) => {
      state.clients.filters = initialState.clients.filters;
    },
    setTreeTowers: (state, { payload }) => {
      state.tree_towers.nodes = [];
      state.tree_towers.edges = [];
      payload.map((item) => {
        state.tree_towers.nodes.push({
          id: item.id,
          type: "custom",
          data: {
            label: item.name,
            ip: item.ip,
            is_selected: item.is_selected,
          },
          position: { x: 0, y: 0 },
        });
      });
      state.tree_towers.edges = payload;
      state.tree_towers.loading = false;
    },
    setIsTreeTowersPage: (state, { payload }) => {
      if (payload !== undefined) {
        state.tree_towers.is_tree_towers_page = payload;
      } else {
        state.tree_towers.is_tree_towers_page =
          !state.tree_towers.is_tree_towers_page;
      }
    },
  },
});

export const {
  setLoading,
  setRoles,
  setPermissions,
  setDialog,

  setClient,
  setClientProfile,
  setFilters,
  resetFilters,
  resetForm,
  setTreeTowers,
  setIsTreeTowersPage,
} = rolesPermissionsSlice.actions;

export default rolesPermissionsSlice.reducer;

//axios
const index = () => async (dispatch) => {
  try {
    dispatch(setLoading("roles"));
    const res = await rolesPermissionsApi.allRoles();
    dispatch(permissions());
    dispatch(setRoles(res));
    dispatch(setLoading("roles"));
  } catch (err) {
    dispatch(setLoading("roles"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const permissions = () => async (dispatch) => {
  try {
    dispatch(setLoading("permissions"));
    const res = await rolesPermissionsApi.allPermissions();
    dispatch(setPermissions(res));
    dispatch(setLoading("permissions"));
  } catch (err) {
    dispatch(setLoading("permissions"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("role"));
    await rolesPermissionsApi.createRole(data);
    dispatch(setLoading("role"));
    dispatch(
      showNotification({
        type: "success",
        message: "Role created successfully",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("role"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const update = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("role"));
    await rolesPermissionsApi.updateRole(data);
    await rolesPermissionsApi.assignPermissionToRole(data);

    dispatch(setLoading("role"));
    dispatch(
      showNotification({
        type: "success",
        message: "Role & Permissions updated successfully",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manage_client"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
// const changeStatus = (id, status) => async (dispatch, getState) => {
//   try {
//     dispatch(setLoading("clients"));
//     await rolesPermissionsApi.changeStatus(id, status);
//     dispatch(setLoading("clients"));
//     dispatch(
//       showNotification({
//         type: "success",
//         message: "Client status changed successfully",
//       })
//     );
//     dispatch(index());
//   } catch (err) {
//     dispatch(setLoading("clients"));
//     dispatch(
//       showNotification({
//         type: "error",
//         message: err.response.data.errors[0],
//       })
//     );
//     throw new Error(err);
//   }
// };

export const RolesPermissions = {
  index,
  permissions,
  create,
  update,
  // changeStatus,
};
