import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  TextField,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import {
  Reports,
  resetFilters,
  setFilters,
} from "../../../app/slices/reportsSlice";
import dayjs from "dayjs";
import { SelectDepartment } from "../../../components/Selects/SelectDepartment";
import { SelectEmployee } from "../../../components/Selects/SelectEmployee";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const TicketsFilters = () => {
  const dispatch = useDispatch();
  const {
    filters: { departmentTicketsSummary },
    data,
    loading,
  } = useSelector((state) => state.reports);

  useEffect(() => {
    return () => dispatch(resetFilters({ type: "departmentTicketsSummary" }));
  }, []);
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" component="div">
            Department tickets summary
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {/* <Typography sx={{ mb: 1 }}>Created filter</Typography> */}
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: "From-Date", end: "To-Date" }}
            >
              <DateRangePicker
                value={[
                  departmentTicketsSummary.start_created_at,
                  departmentTicketsSummary.end_created_at,
                ]}
                onChange={(newValue) => {
                  dispatch(
                    setFilters({
                      type: "departmentTicketsSummary",
                      data: {
                        start_created_at: newValue[0]
                          ? dayjs(newValue[0]).format("YYYY-MM-DD")
                          : null,
                        end_created_at: newValue[1]
                          ? dayjs(newValue[1]).format("YYYY-MM-DD")
                          : null,
                      },
                    })
                  );
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} size="small" />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} size="small" />
                  </>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}>
            <SelectDepartment
              value={departmentTicketsSummary.department_id}
              disabled={departmentTicketsSummary.related_user_id}
              fullWidth
              onChange={(e, value) => {
                dispatch(
                  setFilters({
                    type: "departmentTicketsSummary",
                    data: {
                      department_id: value,
                      related_user_id: null,
                    },
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectEmployee
              value={departmentTicketsSummary.related_user_id}
              disabled={departmentTicketsSummary.department_id}
              fullWidth
              onChange={(e, value) => {
                dispatch(
                  setFilters({
                    type: "departmentTicketsSummary",
                    data: {
                      related_user_id: value,
                      department_id: null,
                    },
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                dispatch(resetFilters({ type: "departmentTicketsSummary" }));
                if (data.length > 0) {
                  dispatch(Reports.fetch("departmentTicketsSummary"));
                }
              }}
              sx={{
                textTransform: "capitalize",
              }}
            >
              Clear Filters
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              disabled={
                !departmentTicketsSummary.department_id &&
                !departmentTicketsSummary.related_user_id
              }
              color="primary"
              onClick={() => {
                dispatch(Reports.fetch("departmentTicketsSummary"));
              }}
              sx={{
                textTransform: "capitalize",
              }}
            >
              Show results
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TicketsFilters;
