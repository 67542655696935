import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";

const itemsApi = factory.get("items");

const initialState = {
  items: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
      location_id: null, //store items locations
      location_type: null, //store items locations
      item_id: null, //store items locations
    },
    filter_dialog: false,
  },
  item: {
    form: {
      id: "",
      name: "",
      description: "",
      price: 0,
      // bar_code: "",
      min_stock: 0,
      type: null,
      brand: null,
    },
    store_item: {
      form: {
        id: null,
        item: null,
        location: null,
        location_type: "Store",
        cost: "0",
        count: "1",
        sn: [],
      },
      form_table: [],
    },
    transferItemOrder: {
      dialog: false,
      form: {
        date: dayjs().format("YYYY-MM-DD"),
        attachment: [],
        status: "pending", //pending, approved, canceled
        ticket_id: "",
        ticket_number: null,
        vendor_id: null,
        type: "transfer", //buy, transfer
        from_id: null,
        from_type: "Store", //ATS, User, Store
        to_id: null,
        to_type: "Store", //ATS, User, store
        items: [],
        sn: [],
        note: "",
        cost_price: "",
      },
    },
    dialog: false,
    loading: false,
    errors: {},
  },
};

export const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setItems: (state, action) => {
      state.items.data = action.payload.data.data;
      state.items.total = action.payload.data.total;
    },
    setItem: (state, action) => {
      state.item.form = { ...action.payload.data };
    },
    setClientProfile: (state, action) => {
      state.client_profile.profile = action.payload.profile.data[0];
      state.client_profile.ats = action.payload.profile.data[0].ats;
      state.client_profile.statistics = {
        ...action.payload.statistics.data,
      };
    },
    resetForm: (state, action) => {
      state.item.form = initialState.item.form;
    },
    setFilters: (state, { payload }) => {
      state.items.filters = {
        ...state.items.filters,
        ...payload,
      };
    },
    setDialog: (state, { payload }) => {
      state.item.store_item.form_table = [];
      if (payload) {
        Object.keys(state.item.form).map((key) => {
          if (key === "type" || key === "brand") {
            state.item.form[key] = payload[key];
          } else {
            state.item.form[key] = payload[key];
          }
        });
      } else {
        state.item.form = initialState.item.form;
      }
      state.item.dialog = !state.item.dialog;
    },
    setTransferItemOrderDialog: (state, { payload }) => {
      if (payload?.data) {
        state.item.transferItemOrder.form.from_id = {
          id: payload?.data.location.id,
        };
        state.item.transferItemOrder.form.from_type =
          payload?.data.location.type;
        state.item.transferItemOrder.form.items.push(payload?.data.item);
        state.item.transferItemOrder.form.sn = payload?.data.serial_numbers;
        state.item.transferItemOrder.form.cost_price = payload?.data.cost_price;
        state.item.transferItemOrder.form.count = payload?.data.count;
        state.item.transferItemOrder.form.note = payload?.data.notes;
      } else {
        state.item.transferItemOrder.form =
          initialState.item.transferItemOrder.form;
      }
      state.item.transferItemOrder.dialog =
        !state.item.transferItemOrder.dialog;
    },
    resetFilters: (state) => {
      state.items.filters = initialState.items.filters;
    },
    setItemsLocationFiltersDialog: (state, action) => {
      state.items.filter_dialog = !state.items.filter_dialog;
    },
    clearItems: (state) => {
      state.items = initialState.items;
    },
    addToStoreItemLocationFormTable: (state, { payload }) => {
      state.item.store_item.form_table.push({
        ...payload,
        id:
          new Date().getTime().toString() + Math.floor(Math.random() * 1000000),
      });
      state.item.store_item.form = initialState.item.store_item.form;
    },
    removeItemLocationTable: (state, { payload }) => {
      const items = state.item.store_item.form_table.filter(
        (item) => item.id !== payload
      );
      state.item.store_item.form_table = items;
    },

    resetFormTable: (state, { payload }) => {
      state.item.store_item.form_table = [];
    },
  },
});

export const {
  setLoading,
  setItems,
  setItem,
  setClientProfile,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setItemsLocationFiltersDialog,
  clearItems,
  addToStoreItemLocationFormTable,
  removeItemLocationTable,
  resetFormTable,
  setTransferItemOrderDialog,
} = itemsSlice.actions;

export default itemsSlice.reducer;

//axios
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().items.items.filters;
    dispatch(setLoading("items"));
    const res = await itemsApi.index(filters);
    dispatch(setItems(res));
    dispatch(setLoading("items"));
  } catch (err) {
    dispatch(setLoading("items"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
export const storeItemsToExcel = () => async (dispatch, getState) => {
  try {
    const { page, skip, take, ...rest } = getState().items.items.filters;
    dispatch(setLoading("items"));
    const res = await itemsApi.exportToExcel({ ...rest, export: "excel" });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "store_items.xlsx";
    a.click();
    dispatch(setLoading("items"));
  } catch (err) {
    dispatch(setLoading("items"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
  }
};
const indexStoreItemsLocation = () => async (dispatch, getState) => {
  try {
    const filters = getState().items.items.filters;

    const _filters = {
      ...filters,
      location_id: filters.location_id?.id ? filters.location_id.id : null,
      location_type: filters.location_type ? filters.location_type : null,
      item_id: filters.item_id ? filters.item_id?.id : null,
    };
    dispatch(setLoading("items"));
    const res = await itemsApi.getStoreItems(_filters);
    dispatch(setItems(res));
    dispatch(setLoading("items"));
  } catch (err) {
    dispatch(setLoading("items"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const createStoreItem = () => async (dispatch, getState) => {
  try {
    const data = getState().items.item.store_item.form_table;
    dispatch(setLoading("item"));

    let form = [];

    data.map((item) => {
      form.push({
        location_id: item.location.id,
        item_id: item.item.id,
        location_type: item.location_type,
        cost: item.cost,
        count: item.count,
        sn: JSON.stringify(item.sn),
      });
    });

    await itemsApi.createStoreItem(form);
    dispatch(setLoading("item"));
    dispatch(
      showNotification({
        type: "success",
        message: "Item Location Created Successfully",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(indexStoreItemsLocation());
    dispatch(resetFormTable());
  } catch (err) {
    dispatch(setLoading("item"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const showById = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("client_profile"));
    const res_profile = await itemsApi.showById(id);
    const res_statistics = await itemsApi.statistics(id);
    dispatch(
      setClientProfile({
        profile: res_profile.data,
        statistics: res_statistics.data,
      })
    );
    dispatch(setLoading("client_profile"));
  } catch (err) {
    dispatch(setLoading("client_profile"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("item"));
    const form = {
      ...data,
      type_id: data.type.id,
      brand_id: data.brand.id,
    };

    await itemsApi.create(form);
    dispatch(setLoading("item"));
    dispatch(
      showNotification({
        type: "success",
        message: "Item Created Successfully",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("item"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const update = (data) => async (dispatch, getState) => {
  try {
    const { id } = getState().items.item.form;
    dispatch(setLoading("item"));
    const form = {
      ...data,
      type_id: data.type.id,
      brand_id: data.brand.id,
    };

    await itemsApi.update(id, form);
    dispatch(setLoading("item"));
    dispatch(
      showNotification({
        type: "success",
        message: "Item Updated Successfully",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("item"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const Delete = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("item"));
    await itemsApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Item Updated Successfully",
      })
    );
    dispatch(index());
    dispatch(setLoading("item"));
  } catch (err) {
    dispatch(setLoading("item"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

export const Items = {
  index,
  showById,
  create,
  update,
  Delete,
  indexStoreItemsLocation,
  createStoreItem,
  storeItemsToExcel,
};
