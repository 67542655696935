import api from "../../api/apiConfig";
import dayjs from "dayjs";

// eslint-disable-next-line import/no-anonymous-default-export
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      params.append(key, p[key]);
    });
  }
  return params;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  cards() {
    return api.get(`/api/v1/statistics/cards`);
  },
  barChart(p) {
    const sevenDaysFromNow = dayjs().subtract(14, "day").format("YYYY-MM-DD");
    return api.get(
      `/api/v1/statistics/ticket-chart?from=${sevenDaysFromNow}&to=${dayjs().format(
        "YYYY-MM-DD"
      )}`
    );
  },
  ticketsByDepartment(data) {
    return api.get(`/api/v1/statistics/cards/department`, data);
  },
};
