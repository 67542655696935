import { createSlice } from "@reduxjs/toolkit";

const groupByTag = (x, f) =>
  x.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});

const initialState = {
  permissions: [],
  groupedPermissions: {},
  data: [],
  roles: [],
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      return {
        ...state,
        ...action.payload,
        groupedPermissions: groupByTag(action.payload.data, (item) => item.tag),
      };
    },
    setRoles: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setPermissions, setRoles } = permissionsSlice.actions;
export default permissionsSlice.reducer;
