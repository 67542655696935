import api from "../../apiConfig";
import paramsFactory from "../../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/item?${p}`);
  },
  list(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/item/list?${p}`);
  },
  showById(id) {
    return api.get(`/api/v1/item/${id}`);
  },
  create(data) {
    return api.post(`/api/v1/item`, data);
  },
  update(id, data) {
    return api.put(`/api/v1/item/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/item/${id}`);
  },

  //store items
  getStoreItems(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/store-item?${p}`);
  },
  createStoreItem(data) {
    return api.post(`/api/v1/add-store-item`, data);
  },
  exportToExcel(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/store-item?${p}`, {
      responseType: "blob",
    });
  },
};
