import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";

const employeesApi = factory.get("employees");
const rolesPermissionsApi = factory.get("roles_permissions");

const initialState = {
  employees: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
      is_active: "",
      department_id: null,
    },
    dialog: false,
  },
  employee_profile: {
    profile: {
      id: "",
      first_name: "",
      full_name: "",
      email: "",
      department_id: null,
      department_name: null,
      active: true,
      gender: "",
      roles: [],
      permissions: [],
      profile_pic: "",
    },
    loading: false,
  },
  manage_employee: {
    form: {
      id: "",
      role: {
        id: null,
        name: "",
        permissions: [],
      },
      departments_id: [],
      tags_id: [],
    },
    dialog: false,
    loading: false,
    errors: {},
  },
};

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setemployees: (state, action) => {
      state.employees.data = action.payload.data.data;
      state.employees.total = action.payload.data.total;
    },
    setClient: (state, action) => {
      const { id, name, full_name, email, phone_1, phone_2, notes } =
        action.payload.data[0];
      state.manage_employee.form = {
        id,
        name,
        full_name,
        email,
        phone_1,
        phone_2,
        notes,
        password: "",
      };
      state.manage_employee.ats = action.payload.data[0].ats;
    },
    setEmployeeProfile: (state, action) => {
      state.employee_profile.profile = action.payload;
    },
    resetForm: (state, action) => {
      state.manage_employee.form = initialState.manage_employee.form;
    },
    setFilters: (state, { payload }) => {
      state.employees.filters = {
        ...state.employees.filters,
        ...payload,
      };
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manage_employee.form.id = payload.id;
        if (payload.role) {
          state.manage_employee.form.role.name = 0;
          state.manage_employee.form.role.name = payload.role;
          state.manage_employee.form.role.permissions = payload.permissions.map(
            (p) => p.name
          );
        } else {
          state.manage_employee.form.role = null;
        }
      } else state.manage_employee.form = initialState.manage_employee.form;
      state.manage_employee.dialog = !state.manage_employee.dialog;
    },
    setEmployeeFiltersDialog: (state, action) => {
      state.employees.dialog = !state.employees.dialog;
    },
    resetFilters: (state) => {
      state.employees.filters = initialState.employees.filters;
    },
    setTagsAndDepartments: (state, { payload }) => {
      if (payload.show_departments.length > 0)
        payload.show_departments.map((department) => {
          state.manage_employee.form.departments_id.push(department.id);
        });
      if (payload.show_tags.length > 0)
        payload.show_tags.map((tag) => {
          state.manage_employee.form.tags_id.push(tag.id);
        });
    },
  },
});

export const {
  setLoading,
  setemployees,
  setClient,
  setEmployeeProfile,
  setTagsAndDepartments,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setEmployeeFiltersDialog,
} = employeesSlice.actions;

export default employeesSlice.reducer;

//axios
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().employees.employees.filters;
    dispatch(setLoading("employees"));
    const res = await employeesApi.index({
      ...filters,
      department_id: filters.department_id?.id,
    });
    dispatch(setemployees(res));
    dispatch(setLoading("employees"));
  } catch (err) {
    dispatch(setLoading("employees"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const showById = (id) => async (dispatch, getState) => {
  try {
    const res = await employeesApi.showById(id);
    dispatch(setTagsAndDepartments(res.data?.data[0]));
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response?.data?.errors[0],
      })
    );
    throw new Error(err);
  }
};
const assignDepartmentTags = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("manage_employee"));

    await employeesApi.assignDepartmentTags({
      user_id: data.id,
      departments_id: data.departments_id,
      tags_id: data.tags_id,
    });
    dispatch(
      showNotification({
        type: "success",
        message: "Employee Tags & Departments updated successfully",
      })
    );
    dispatch(setDialog());
    dispatch(index());
    dispatch(setLoading("manage_employee"));
  } catch (err) {
    dispatch(setLoading("manage_employee"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const toggleStatus = (id) => async (dispatch, getState) => {
  try {
    await employeesApi.toggleStatus(id);
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const update = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("manage_employee"));
    const request = {
      user_id: data.id,
      permissions: data.role.permissions,
    };
    await rolesPermissionsApi.assignRoleToUser(request);
    dispatch(setLoading("manage_employee"));
    dispatch(
      showNotification({
        type: "success",
        message: "Employee Role updated successfully",
      })
    );

    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manage_employee"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

export const Employees = {
  index,
  showById,
  assignDepartmentTags,
  update,
  toggleStatus,
};
