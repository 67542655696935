import { lazy } from "react";
// project imports
import MainLayout from "../layout/MainLayout";
import Loadable from "./../components/utils/Loadable";
import Reports from "../pages/reports";

// dashboard routing
const Home = Loadable(lazy(() => import("../pages/home")));
const Clients = Loadable(lazy(() => import("../pages/clients")));
const ClientsProfile = Loadable(
  lazy(() => import("../pages/clients/profile/index"))
);

//Companies
const Companies = Loadable(lazy(() => import("../pages/companies/index")));

//roles & permissions
const RolesPermissions = Loadable(
  lazy(() => import("../pages/roles_permissions/"))
);
const Tickets = Loadable(lazy(() => import("../pages/tickets")));
const TicketsDetails = Loadable(
  lazy(() => import("../pages/tickets/TicketDetails"))
);
const ATS = Loadable(lazy(() => import("../pages/ats")));
const Employees = Loadable(lazy(() => import("../pages/employees")));
const EmployeeDetails = Loadable(
  lazy(() => import("../pages/employees/EmployeeDetails"))
);

const DepartmentTags = Loadable(lazy(() => import("../pages/department-tags")));

//Store
const Constants = Loadable(lazy(() => import("../pages/Store/Constants")));
const Items = Loadable(lazy(() => import("../pages/Store/Items")));
const ItemsLocations = Loadable(
  lazy(() => import("../pages/Store/ItemsLocation"))
);
const Orders = Loadable(lazy(() => import("../pages/Store/Orders")));
const Profile = Loadable(lazy(() => import("../pages/profile")));
const Categories = Loadable(
  lazy(() => import("../pages/categories-subcategories"))
);
const CategoryDetailes = Loadable(
  lazy(() => import("../pages/categories-subcategories/CategoryDetailes"))
);
const Cabinets = Loadable(lazy(() => import("../pages/Store/Cabinets")));
const OrderItems = Loadable(lazy(() => import("../pages/Store/OrderItems")));

const Requests = Loadable(lazy(() => import("../pages/requests/")));
const RequestDetails = Loadable(
  lazy(() => import("../pages/requests/RequestDetails/"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/clients",
      children: [
        {
          index: true,
          element: <Clients />,
        },
        {
          path: "/clients/:id",
          element: <ClientsProfile />,
        },
      ],
    },
    {
      path: "/tickets",
      children: [
        {
          index: true,
          element: <Tickets />,
        },
        // {
        //   path: "/tickets/add",
        //   element: <EmployeeManage />,
        // },
        {
          path: "/tickets/:id",
          element: <TicketsDetails />,
        },
      ],
    },
    {
      path: "/ats",
      children: [
        {
          index: true,
          element: <ATS />,
        },
        // {
        //   path: "/ats/add",
        //   element: <EmployeeManage />,
        // },
        // {
        //   path: "/ats/edit/:id",
        //   element: <EmployeeManage />,
        // },
      ],
    },
    {
      path: "/employees",
      children: [
        {
          index: true,
          element: <Employees />,
        },
        {
          path: "/employees/:id",
          element: <EmployeeDetails />,
        },
      ],
    },
    {
      path: "/roles-permissions",
      children: [
        {
          index: true,
          element: <RolesPermissions />,
        },
      ],
    },
    {
      path: "/store/constants",
      children: [
        {
          index: true,
          element: <Constants />,
        },
        {
          path: "/store/constants/:id",
          element: <Constants />,
        },
      ],
    },
    {
      path: "/store/items",
      children: [
        {
          index: true,
          element: <Items />,
        },
        {
          path: "/store/items/:id",
          element: <Items />,
        },
      ],
    },
    {
      path: "/store/items-location",
      children: [
        {
          index: true,
          element: <ItemsLocations />,
        },
        // {
        //   path: "/store/items/:id",
        //   element: <ItemsLocations />,
        // },
      ],
    },
    {
      path: "/store/cabinets",
      children: [
        {
          index: true,
          element: <Cabinets />,
        },
      ],
    },
    {
      path: "/store/orders",
      children: [
        {
          index: true,
          element: <Orders />,
        },
        // {
        //   path: "/store/items/:id",
        //   element: <Items />,
        // },
      ],
    },
    {
      path: "/store/order-items",
      children: [
        {
          index: true,
          element: <OrderItems />,
        },
      ],
    },
    {
      path: "/tags",
      children: [
        {
          index: true,
          element: <DepartmentTags />,
        },
        // {
        //   path: "/store/items/:id",
        //   element: <Items />,
        // },
      ],
    },
    {
      path: "/companies",
      children: [
        {
          index: true,
          element: <Companies />,
        },
        // {
        //   path: "/store/items/:id",
        //   element: <Items />,
        // },
      ],
    },
    {
      path: "/categories",
      children: [
        {
          index: true,
          element: <Categories />,
        },
        {
          path: "/categories/:id",
          element: <CategoryDetailes />,
        },
      ],
    },
    {
      path: "/reports",
      element: <Reports />,
    },
    {
      path: "/requests",
      children: [
        {
          index: true,
          element: <Requests />,
        },
        {
          path: "/requests/:id",
          element: <RequestDetails />,
        },
      ],
    },
  ],
};

export default MainRoutes;
