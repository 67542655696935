//import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import axios from "../api/apiConfig";

/**
 * Hook to fetch data from given url and handle fetch request life cycle.
 */
const useFetch = (url, options = {}) => {
  const {
    initialData = null,
    onSuccess,
    onError,
    enabled = true,
    formatData = null,
  } = options;
  const [isFetching, setIsFetching] = useState(enabled);
  const [isEnabled, setIsEnabled] = useState(enabled);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(initialData);

  /**
   * function use to fetch data.
   * @access private
   */
  const fetchData = useCallback((query = "") => {
    setIsFetching(true);
    axios
      .get(`/api/v1${url}${query ? query : ""}`)
      .then((response) => {
        // check if formatData function exists, then execute it with pass response to it,
        // otherwise use orginal response
        const formatedData =
          typeof formatData === "function" ? formatData(response) : response;

        setData(formatedData);
        setIsSuccess(true);
        // check if onSuccess function exists, then execute it and pass data to it
        if (onSuccess) {
          onSuccess(formatedData);
        }
      })
      .catch((error) => {
        setIsError(true);
        // check if onError function exists, then execute it and pass error to it
        if (onError) {
          onError(error);
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  /**
   * Use to make fetch conditionaly, make sure to make enabled option to false when use conditional fetch request.
   */
  const enable = useCallback(() => {
    setIsEnabled(true);
  }, []);

  /**
   * Refetch data.
   */
  const refetch = useCallback((query = "") => {
    fetchData(query);
  }, []);

  useEffect(() => {
    if (isEnabled) {
      fetchData();
    }
  }, [isEnabled]);

  return { data, isFetching, isError, isSuccess, enable, refetch };
};

export default useFetch;
