import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import { setProfile } from "./profileSlice";
import Cookies from "js-cookie";
import OneSignal from "react-onesignal";

const authApi = factory.get("auth");

const initialState = {
  auth: {
    loading: false,
    token: "",
  },
  form: {
    email: process.env.NODE_ENV === "development" ? "admin@halasat.com" : "",
    password: process.env.NODE_ENV === "development" ? "1234567800" : "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setToken: (state, action) => {
      Cookies.set("ticket_hala_system", action.payload);
      window.location.href = "/tickets";
    },
    resetForm: (state, action) => {
      state.flexibility.form = initialState.flexibility.form;
    },
  },
});

export const { setLoading, setToken, resetForm } = authSlice.actions;

export default authSlice.reducer;

//axios
const login = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("auth"));
    const res = await authApi.login({
      email: data.email,
      password: data.password,
    });
    // dispatch(setProfile(res.data.data[0]));
    dispatch(setToken(res.data.token));
    dispatch(setLoading("auth"));
  } catch (err) {
    dispatch(setLoading("auth"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const logout = () => async (dispatch) => {
  try {
    dispatch(setLoading("auth"));
    await authApi.logout().then((res) => {
      Cookies.remove("ticket_hala_system");
      window.location.href = "/login";
    });

    dispatch(setLoading("auth"));
  } catch (err) {
    dispatch(setLoading("auth"));
    console.log(err);
    throw new Error(err);
  } finally {
    await OneSignal.logout();
  }
};

export const Auth = {
  login,
  logout,
};
