import api from "../../apiConfig";
import paramsFactory from "../../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/store?${p}`);
  },
  showById(id) {
    return api.get(`/api/v1/store/${id}`);
  },
  create(data) {
    return api.post(`/api/v1/store`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  delete(id) {
    return api.delete(`/api/v1/store/${id}`);
  },
};
