import api from "../apiConfig";
import paramsFactory from "../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/client?${p}`);
  },
  showById(id) {
    return api.get(`/api/v1/client/${id}`);
  },
  statistics(id) {
    return api.get(`/api/v1/client/statistics/${id}`);
  },
  create(data) {
    return api.post(`/api/v1/client`, data);
  },
  update(id, data) {
    return api.post(`/api/v1/client/${id}`, data);
  },
  changeStatus(id, status) {
    return api.post(`/api/v1/client/change-status/${id}`, { status });
  },
  treeTowers(id) {
    return api.get(`/api/v1/client/tower-tree/${id}`);
  },
  changePassword(data) {
    return api.post(`/api/v1/client/admin-reset-password`, data);
  },
  verified(id) {
    return api.post(`/api/v1/client/verified/${id}`);
  },
  unVerified(id) {
    return api.post(`/api/v1/client/unverified/${id}`);
  },
  removeClient(id) {
    return api.delete(`/api/v1/client/${id}`);
  },
  restoreClient(id) {
    return api.post(`/api/v1/client/restore/${id}`);
  },
};
