import auth from "./endpoints/auth";
import clients from "./endpoints/clients";
import ats from "./endpoints/ats";
import ats_device from "./endpoints/ats_device";
import tickets from "./endpoints/tickets";
import others from "./endpoints/others";
import employees from "./endpoints/employees";
import constants from "./endpoints/store/constants";
import items from "./endpoints/store/items";
import orders from "./endpoints/store/orders";
import stores from "./endpoints/store/stores";
import tags from "./endpoints/tags";
import roles_permissions from "./endpoints/roles_permissions";
import statistics from "./endpoints/statistics";
import companies from "./endpoints/companies";
import cabinets from "./endpoints/store/cabinets";
import orderItems from "./endpoints/store/orderItems";
import reports from "./endpoints/reports";
import requests from "./endpoints/requests";
const repositories = {
  auth,
  clients,
  ats,
  tickets,
  ats_device,
  others,
  employees,
  reports,
  requests,
  // store
  constants,
  items,
  orders,
  stores,
  tags,
  roles_permissions,
  statistics,
  companies,
  cabinets,
  orderItems,
};

export const factory = {
  get: (name) => repositories[name],
};
