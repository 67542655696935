import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const statisticsApi = factory.get("statistics");

const initialState = {
  data: {
    loading: false,
    cards: {
      employees: 0,
      clients: 0,
      towers: 0,
      tickets: 0,
    },
    ticketsChart: [],
  },
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setDataTable: (state, { payload }) => {
      state.data[payload.key] = payload.value;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setCards: (state, { payload }) => {
      state.data.cards = payload.data.data;
    },
    setChart: (state, { payload }) => {
      state.data.ticketsChart = payload.data.data;
    },
  },
});

export const { setLoading, setDataTable, setCards, setChart } =
  statisticsSlice.actions;

export default statisticsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("data"));

    const cardsRes = await statisticsApi.cards();
    const ticketsChart = await statisticsApi.barChart();
    // const leaveAppsRes = await statisticsApi.leaveApps();

    // dispatch(
    //   setDataTable({ key: "usersDayOff", value: usersDayOffRes.data.data })
    // );
    // dispatch(setDataTable({ key: "leaveApps", value: leaveAppsRes.data.data }));
    dispatch(setCards(cardsRes));
    dispatch(setChart(ticketsChart));

    dispatch(setLoading("data"));
  } catch (err) {
    dispatch(setLoading("data"));
    throw new Error(err);
  }
};

export const Statistics = {
  getAll,
};
