import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { useEffect, forwardRef, Fragment, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { factory } from "../../api/apiFactory";
import { useSelector } from "react-redux";
const othersApi = factory.get("others");

export const SelectDepartment = forwardRef((props, ref) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchDepartments = () => {
    setLoading(true);
    othersApi.departments({ skip: 0, take: 100 }).then((res) => {
      setOptions(res.data.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchDepartments();
  }, []);

  // useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  return (
    <Autocomplete
      {...props}
      ref={ref}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id || ""}
      getOptionLabel={(option) => `${option.name} `}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.required}
          helperText={props.helpertext}
          label={props.label || "Departments"}
          size="small"
          disabled={props.disabled}
          onChange={(e, value) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
});
