import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import { ClientsCall } from "./clientsSlice";

const Atsapi = factory.get("ats");
const ats_deviceApi = factory.get("ats_device");

const initialState = {
  ats: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
      deleted: null,
      // is_repeater_or_sub: 0,
    },
    map: {
      longitude: 0,
      latitude: 0,
      dialog: false,
    },
  },
  ats_form: {
    form: {
      id: "",
      client_id: "",
      name: "",
      title: "",
      ip: "",
      subnet: "",
      parent_id: null,
      longitude: "",
      latitude: "",
      belongs_to_id: null,
      sales_user_id: null,
      users: 0,
      backup_ip: "",
      server_ip: "",
      is_main: false,
      is_online: false,
      is_ats_properties_empty: false,
      checkbox: true,
      is_repeater_or_sub: false,
      isp_company_id: null,
      billing_password: "",
      ats_properties: {
        type: "wireless", //fiber
        has_backup: null,
        free_maintenance_main: null,
        free_maintenance_backup: null,
        borrow_devices: null,
        borrow_devices_period: dayjs().format("YYYY-MM-DD"),
        borrow_devices_period_remaining: 0,
      },
    },
    dialog: false,
    loading: false,
    errors: {},
  },
  ats_device: {
    form: {
      id: "",
      name: "",
      ats_id: "",
      username: "",
      password: "",
      note: "",
    },
    dialog: false,
    loading: false,
    errors: {},
  },
};

export const atsSlice = createSlice({
  name: "ats",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setData: (state, action) => {
      state.ats.data = action.payload.data;
      state.ats.total = action.payload.total;
    },
    setAts: (state, action) => {
      const { id, name, full_name, email, phone_1, phone_2, notes } =
        action.payload.data[0];
      state.ats_form.form = {
        id,
        name,
        full_name,
        email,
        phone_1,
        phone_2,
        notes,
        password: "",
      };
    },
    resetForm: (state, action) => {
      state.ats_form.form = initialState.ats_form.form;
    },
    setFilters: (state, { payload }) => {
      state.ats.filters = {
        ...state.ats.filters,
        ...payload,
      };
    },
    setDialogAts: (state, { payload }) => {
      if (payload) {
        if (payload.action === "edit") {
          // state.ats_form.form = {
          //   ...payload.data,
          //   belongs_to_id: payload.data.belongsToATS,
          //   parent_id: payload.data.parent,
          //   is_main: payload.data.is_main ? "true" : "false",
          //   is_online: payload.data.is_online ? "true" : "false",
          //   is_repeater_or_sub: payload.data.is_repeater_or_sub
          //     ? "true"
          //     : "false",
          //   is_ats_properties_empty: payload.data.ats_properties?.type
          //     ? true
          //     : false,
          //   checkbox: payload.data.ats_properties?.type ? true : false,
          //   isp_company_id: payload.data.isp_company
          //     ? payload.data.isp_company
          //     : null,
          //   sales_user_id: payload.data.sales_user
          //     ? {
          //         full_name: payload.data.sales_user?.name,
          //         id: payload.data.sales_user?.id,
          //       }
          //     : null,
          //   ats_properties: payload.data.ats_properties
          //     ? {
          //         ...payload.data.ats_properties,
          //         // borrow_devices_period: payload.data.ats_properties
          //         //   .borrow_devices_period
          //         //   ? dayjs(
          //         //       payload.data.ats_properties.borrow_devices_period
          //         //     ).format("YYYY-MM-DD")
          //         //   : null,
          //         has_backup: payload.data.ats_properties.has_backup
          //           ? "true"
          //           : "false",
          //         free_maintenance_main: payload.data.ats_properties
          //           .free_maintenance_main
          //           ? "true"
          //           : "false",
          //         free_maintenance_backup: payload.data.ats_properties
          //           .free_maintenance_backup
          //           ? "true"
          //           : "false",
          //         borrow_devices: payload.data.ats_properties.borrow_devices
          //           ? "true"
          //           : "false",
          //       }
          //     : {
          //         type: "",
          //         has_backup: "",
          //         free_maintenance_main: "",
          //         free_maintenance_backup: "",
          //         borrow_devices: "",
          //         borrow_devices_period: "",
          //         borrow_devices_period_remaining: 0,
          //       },
          // };
          state.ats_form.form = payload.data;
        } else {
          state.ats_form.form = initialState.ats_form.form;
        }
      }
      state.ats_form.dialog = !state.ats_form.dialog;
    },
    setDialogDevice: (state, { payload }) => {
      if (payload.action === "edit") {
        state.ats_device.form = { ...payload.data };
        state.ats_device.form.ats_id = payload.ats_id;
      } else if (payload.action === "add") {
        state.ats_device.form = initialState.ats_device.form;
        state.ats_device.form.ats_id = payload.ats_id;
      } else {
        state.ats_device.form = initialState.ats_device.form;
      }
      state.ats_device.dialog = !state.ats_device.dialog;
    },
    resetFilters: (state) => {
      state.ats.filters = initialState.ats.filters;
    },
    setMapDialog: (state, { payload }) => {
      state.ats.map.dialog = !state.ats.map.dialog;
      if (payload) {
        state.ats.map.latitude = payload.latitude;
        state.ats.map.longitude = payload.longitude;
      } else {
        state.ats.map.latitude = 0;
        state.ats.map.longitude = 0;
      }
    },
  },
});

export const {
  setLoading,
  setData,
  setAts,
  setFilters,
  resetFilters,
  setDialogAts,
  setDialogDevice,
  resetForm,
  setMapDialog,
} = atsSlice.actions;

export default atsSlice.reducer;

//axios
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().ats.ats.filters;
    dispatch(setLoading("ats"));
    const res = await Atsapi.index(filters);
    dispatch(setData(res.data));
    dispatch(setLoading("ats"));
  } catch (err) {
    dispatch(setLoading("ats"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const showById = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("ats"));
    const res = await Atsapi.showById(id);
    dispatch(setData(res));
    dispatch(setLoading("ats"));
  } catch (err) {
    dispatch(setLoading("ats"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data, id) => async (dispatch, getState) => {
  try {
    const convertToBoolean = (value) => {
      return value === "true" ? true : false;
    };
    const client = getState().clients.client_profile.profile;
    const is_borrow = convertToBoolean(data.ats_properties.borrow_devices);
    let form = {
      ...data,
      belongs_to_id: data.belongs_to_id?.id || null,
      parent_id: data.parent_id?.id || null,
      client_id: id,
      devices: null,
      sales_user_id: data.sales_user_id?.id || null,
      is_main: data.is_main,
      is_online: data.is_online,
      is_repeater_or_sub: data.is_repeater_or_sub,
      isp_company_id: data.isp_company_id?.id || null,
      ats_properties: {
        type: data.ats_properties.type,
        has_backup: data.ats_properties.has_backup,
        borrow_devices: data.ats_properties.borrow_devices,
        free_maintenance_main: data.ats_properties.free_maintenance_main,
        free_maintenance_backup: data.ats_properties.free_maintenance_backup,
        borrow_devices_period: is_borrow
          ? dayjs(data.ats_properties.borrow_devices_period).format(
              "YYYY-MM-DD"
            )
          : null,
      },
    };

    dispatch(setLoading("ats_form"));
    await Atsapi.create(form);
    dispatch(setLoading("ats_form"));
    dispatch(
      showNotification({
        type: "success",
        message: "Ats created successfully",
      })
    );
    dispatch(setDialogAts());
    dispatch(resetForm());
    id ? dispatch(ClientsCall.showById(client.id)) : dispatch(index());
  } catch (err) {
    dispatch(setLoading("ats_form"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const update = (data, id) => async (dispatch, getState) => {
  try {
    const convertToBoolean = (value) => {
      return value === "true" ? true : false;
    };
    const is_borrow = convertToBoolean(data.ats_properties.borrow_devices);

    const client = getState().clients.client_profile.profile;
    let form = {
      ...data,
      belongs_to_id: data.belongs_to_id?.id || null,
      parent_id: data.parent_id?.id || null,
      client_id: data.client_id,
      devices: null,
      sales_user_id: data.sales_user_id?.id || null,
      is_main: data.is_main,
      is_online: data.is_online,
      is_repeater_or_sub: data.is_repeater_or_sub,
      isp_company_id: data.isp_company_id?.id || null,
      ats_properties: {
        type: data.ats_properties.type,
        has_backup: data.ats_properties.has_backup,
        borrow_devices: data.ats_properties.borrow_devices,
        free_maintenance_main: data.ats_properties.free_maintenance_main,
        free_maintenance_backup: data.ats_properties.free_maintenance_backup,
        borrow_devices_period: is_borrow
          ? dayjs(data.ats_properties.borrow_devices_period).format(
              "YYYY-MM-DD"
            )
          : null,
      },
    };

    dispatch(setLoading("ats_form"));
    await Atsapi.update(data.id, form);
    dispatch(setLoading("ats_form"));
    dispatch(
      showNotification({
        type: "success",
        message: "Ats updated successfully",
      })
    );
    dispatch(setDialogAts());
    dispatch(resetForm());
    id ? dispatch(ClientsCall.showById(client.id)) : dispatch(index());
  } catch (err) {
    dispatch(setLoading("ats_form"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const deleteAts =
  (id, clientProfile = false, clintId) =>
  async (dispatch, getState) => {
    try {
      await Atsapi.delete(id);
      dispatch(
        showNotification({
          type: "success",
          message: "Tower deleted successfully",
        })
      );
      clientProfile
        ? dispatch(ClientsCall.showById(clintId))
        : dispatch(index());
    } catch (err) {
      dispatch(
        showNotification({
          type: "error",
          message: err.response.data.errors[0],
        })
      );
      throw new Error(err);
    }
  };
const createDevice = (data) => async (dispatch, getState) => {
  try {
    const client = getState().clients.client_profile.profile;
    dispatch(setLoading("ats_device"));
    await ats_deviceApi.create(data);
    dispatch(setLoading("ats_device"));
    dispatch(
      showNotification({
        type: "success",
        message: "Device created successfully",
      })
    );
    dispatch(setDialogDevice({ action: "close" }));
    dispatch(resetForm());
    dispatch(ClientsCall.showById(client.id));
  } catch (err) {
    dispatch(setLoading("ats_device"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const updateDevice = (data) => async (dispatch, getState) => {
  try {
    const client = getState().clients.client_profile.profile;
    const { id } = getState().ats.ats_device.form;
    dispatch(setLoading("ats_device"));
    await ats_deviceApi.update(id, data);
    dispatch(setLoading("ats_device"));
    dispatch(
      showNotification({
        type: "success",
        message: "Device updated successfully",
      })
    );
    dispatch(setDialogDevice({ action: "close" }));
    dispatch(resetForm());
    dispatch(ClientsCall.showById(client.id));
  } catch (err) {
    dispatch(setLoading("ats_device"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const deleteDevice = (device_id) => async (dispatch, getState) => {
  try {
    const client = getState().clients.client_profile.profile;

    dispatch(setLoading("ats_device"));
    await ats_deviceApi.delete(device_id);
    dispatch(setLoading("ats_device"));
    dispatch(
      showNotification({
        type: "success",
        message: "Device deleted successfully",
      })
    );
    dispatch(resetForm());
    dispatch(ClientsCall.showById(client.id));
  } catch (err) {
    dispatch(setLoading("ats_device"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const restoreAts = (AtsId) => async (dispatch, getState) => {
  try {
    await Atsapi.restoreAts(AtsId);
    dispatch(
      showNotification({
        type: "success",
        message: "ATS restored successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
  }
};

export const AtsCall = {
  index,
  showById,
  create,
  update,
  deleteAts,
  createDevice,
  updateDevice,
  deleteDevice,
  restoreAts,
};
