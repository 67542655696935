import { lazy } from "react";

// project imports
import MinimalLayout from "../layout/MinimalLayout";
import Loadable from "./../components/utils/Loadable";

// auth routing
const AuthLogin = Loadable(lazy(() => import("../pages/login")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
  ],
};

export default AuthenticationRoutes;
