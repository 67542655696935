import api from "../apiConfig";
import paramsFactory from "../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/user?${p}`);
  },
  list(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/user-list?${p}`);
  },
  showById(id) {
    return api.get(`/api/v1/user/${id}`);
  },
  assignDepartmentTags(data) {
    return api.post(`/api/v1/user/tags`, data);
  },
  toggleStatus(id) {
    return api.post("/api/v1/toggle-active", {
      user_id: id,
    });
  },
};
