import { Box, Chip, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export const TicketsColumns = [
  // {
  //   field: "#",
  //   headerName: "#",
  //   flex: 0.2,
  //   disableExport: true,
  //   renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
  // },
  {
    fileld: "ticket_number",
    headerName: "Ticket Number",
    flex: 1,
    renderCell: (params) => {
      return (
        <a href={`/tickets/${params.row.id}`} target="_blanck">
          <Tooltip title={params.row.ticket_number}>
            <Typography>{params.row.ticket_number}</Typography>
          </Tooltip>
        </a>
      );
    },
  },
  {
    field: "title",
    headerName: "Title",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box>
          <Typography color="initial">{params.row.title}</Typography>
          <Typography variant="caption" color="initial">
            {params.row.reply_count &&
              `${params.row.reply_count} ${
                params.row.reply_count == 1 ? `Reply` : "Replies"
              }`}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "created_by_name",
    headerName: "Created by",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box>
          <Typography color="initial">{params.row.created_by_name}</Typography>
          <Typography variant="caption" color="initial">
            {dayjs(params.row.created_at).format("YYYY-MM-DD HH:mm")}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "open_by_department",
    headerName: "Opened by department",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return (
        <Chip
          label={params.row.status.toUpperCase().replace("_", " ")}
          size="small"
          color={
            params.row.status === "open" || params.row.status === "re_open"
              ? "success"
              : params.row.status === "in_progress"
              ? "warning"
              : "error"
          }
          sx={{ mb: "3px" }}
        />
      );
    },
  },
  {
    field: "updated_at",
    headerName: "Updated at",
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography color="initial">
          {dayjs(params.row.updated_at).format("YYYY-MM-DD HH:mm")}
        </Typography>
      );
    },
  },
];
