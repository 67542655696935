import api from "../../apiConfig";
import paramsFactory from "../../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/order?${p}`);
  },
  showById(id) {
    return api.get(`/api/v1/order/${id}`);
  },
  create(data) {
    return api.post(`/api/v1/order`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getClientBrrowItems(client_id) {
    return api.get(
      `/api/v1/store-display?client_id=${client_id}&take=100&skip=0`
    );
  },
  setInService(order_id) {
    return api.get(`/api/v1/set-order-in-service/${order_id}`);
  },
  orderClearance(data) {
    return api.post(`/api/v1/order/clearance`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
