import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: {
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
    },
  },
  subcategories: {
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
    },
  },
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = { ...state.categories, ...action.payload };
    },
    appendToCategories: (state, action) => {
      state.categories.data = [...state.categories.data, action.payload];
      state.categories.total = ++state.categories.total;
    },
    setCategoriesFilters: (state, { payload }) => {
      state.categories.filters = {
        ...state.categories.filters,
        ...payload,
      };
    },
    resetCategoriesFilters: (state, { payload }) => {
      state.categories.filters = {
        ...initialState.categories.filters,
      };
    },
    deleteCategory: (state, { payload }) => {
      state.categories.data = state.categories.data.filter(
        (c) => c.id !== payload.id
      );
      state.categories.total = --state.categories.total;
    },

    setSubcategoies: (state, action) => {
      state.subcategories = { ...state.subcategories, ...action.payload };
    },
    appendToSubcategories: (state, action) => {
      state.subcategories.data = [...state.subcategories.data, action.payload];
      state.subcategories.total = ++state.subcategories.total;
    },
    setSubcategoriesFilters: (state, { payload }) => {
      state.subcategories.filters = {
        ...state.subcategories.filters,
        ...payload,
      };
    },
    resetSubcategoriesFilters: (state, { payload }) => {
      state.subcategories.filters = {
        ...initialState.subcategories.filters,
      };
    },
    updateSubCategory: (state, { payload }) => {
      const index = state.subcategories.data.findIndex(
        (s) => s.id === payload.id
      );
      state.subcategories.data[index] = payload;
    },
    deleteSubCategory: (state, { payload }) => {
      state.subcategories.data = state.subcategories.data.filter(
        (s) => s.id !== payload.id
      );
      state.subcategories.total = --state.subcategories.total;
    },
  },
});

export const {
  setCategories,
  setCategoriesFilters,
  resetCategoriesFilters,
  appendToCategories,
  deleteCategory,
  setSubcategoies,
  setSubcategoriesFilters,
  resetSubcategoriesFilters,
  appendToSubcategories,
  updateSubCategory,
  deleteSubCategory,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
