import api from "../apiConfig";
import paramsFactory from "../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/isp-company?${p}`);
  },

  create(data) {
    return api.post(`/api/v1/isp-company`, data);
  },
  update(data) {
    return api.put(`/api/v1/isp-company/${data.id}`, data);
  },
  delete(id) {
    return api.put(`/api/v1/isp-company/${id}`);
  },
};
