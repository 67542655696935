import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";

const tagsApi = factory.get("tags");
const othersApi = factory.get("others");

const initialState = {
  results: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 100,
      search: "",
    },
  },
  tag: {
    form: {
      id: "",
      name: "",
      department_id: null,
    },
    dialog: false,
    loading: false,
    errors: {},
  },
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setResults: (state, action) => {
      state.results.data = action.payload.data.data;
      state.results.total = action.payload.data.total;
    },
    setTag: (state, action) => {
      state.tag.form = action.payload.data;
      state.tag.loading = false;
    },
    resetForm: (state, action) => {
      state.tag.form = initialState.tag.form;
    },
    setFilters: (state, { payload }) => {
      state.results.filters = {
        ...state.results.filters,
        ...payload,
      };
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.tag.form = payload;
      } else state.tag.form = initialState.tag.form;
      state.tag.dialog = !state.tag.dialog;
    },
    resetFilters: (state) => {
      state.results.filters = initialState.results.filters;
    },
  },
});

export const {
  setLoading,
  setResults,
  setTag,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
} = tagsSlice.actions;

export default tagsSlice.reducer;

//axios
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().tags.results.filters;
    dispatch(setLoading("results"));
    const res = await othersApi.departments(filters);
    dispatch(setResults(res));
    dispatch(setLoading("results"));
  } catch (err) {
    dispatch(setLoading("results"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
// const showById = (id) => async (dispatch, getState) => {
//   try {
//     dispatch(setLoading("tag"));
//     const res = await tagsApi.showById(id);
//     dispatch(setTag(res.data));
//     dispatch(setDialog());
//   } catch (err) {
//     dispatch(setLoading("tag"));
//     dispatch(
//       showNotification({
//         type: "error",
//         message: err.response.data.errors[0],
//       })
//     );
//     throw new Error(err);
//   }
// };
const create = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("tag"));
    await tagsApi.create({
      department_id: data.department_id?.id,
      name: data.name,
    });
    dispatch(setLoading("tag"));
    dispatch(
      showNotification({
        type: "success",
        message: "Tag created successfully",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("tag"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const update = (id, data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("tag"));
    await tagsApi.update(id, {
      department_id: data.department_id?.id,
      name: data.name,
    });
    dispatch(setLoading("tag"));
    dispatch(
      showNotification({
        type: "success",
        message: "Tag updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("tag"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const deleteTag = (id) => async (dispatch, getState) => {
  try {
    // dispatch(setLoading("results"));
    await tagsApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Tag deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("results"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

export const DepartmentTags = {
  index,
  // showById,
  create,
  update,
  deleteTag,
};
