import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login(data) {
    return api.post(`/api/v1/login`, data);
  },
  logout() {
    return api.post(`/api/v1/logout`);
  },
  profile() {
    return api.get("/api/v1/user-info");
  },
  toggleNotify(data) {
    return api.post(`/api/v1/user/toggle-notification`, data);
  },
  togglePushNotification(data) {
    return api.post(`/api/v1/user/toggle-push-notification`, data);
  },
};
