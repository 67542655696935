import api from "../apiConfig";

const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      params.append(key, p[key]);
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  departments(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/department?${p}`);
  },
};
