import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import appSlice from "./slices/appSlice";
import authSlice from "./slices/authSlice";
import profileSlice from "./slices/profileSlice";
import notificationSlice from "./slices/notificationSlice";
import clientsSlice from "./slices/clientsSlice";
import atsSlice from "./slices/atsSlice";
import ticketsSlice from "./slices/ticketsSlice";
import employeesSlice from "./slices/employeesSlice";
import constantsSlice from "./slices/store/constantsSlice";
import itemsSlice from "./slices/store/itemsSlice";
import ordersSlice from "./slices/store/ordersSlice";
import tagsSlice from "./slices/tagsSlice";
import rolesPermissionsSlice from "./slices/rolesPermissionsSlice";
import statisticsSlice from "./slices/statisticsSlice";
import companiesSlice from "./slices/companiesSlice";
import categoriesSlice from "./slices/categories_subcategories";
import permissionsSlice from "./slices/permissionsSlice";
import cabinetsSlice from "./slices/store/cabinetsSlice";
import orderItemsSlice from "./slices/store/orderItemsSlice";
import reportsSlice from "./slices/reportsSlice";
import requestsSlice from "./slices/requestsSlice";
export const store = configureStore({
  reducer: {
    app: appSlice,
    auth: authSlice,
    profile: profileSlice,
    notification: notificationSlice,
    clients: clientsSlice,
    ats: atsSlice,
    tickets: ticketsSlice,
    employees: employeesSlice,
    // store
    constants: constantsSlice,
    items: itemsSlice,
    orders: ordersSlice,
    tags: tagsSlice,
    rolesPermissions: rolesPermissionsSlice,
    statistics: statisticsSlice,
    companies: companiesSlice,
    categories: categoriesSlice,
    permissions: permissionsSlice,
    cabinets: cabinetsSlice,
    orderItems: orderItemsSlice,
    reports: reportsSlice,
    requests: requestsSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);
