import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import { useNavigate } from "react-router-dom";

const ticketsApi = factory.get("tickets");
const requestsApi = factory.get("requests");
const initialState = {
  requests: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      is_my_tickets: null,
      page: 0,
      skip: 0,
      take: 15,
      search: "",
      number: "",
      status: ["in_progress", "open"],
      tags_id: [],
      department_id: null,
      related_department_id: null,
      start_date: null,
      end_date: null,
      start_update_date: null,
      end_update_date: null,
    },
    dialog: false,
    isFilterCleared: false,
  },
  request: {
    form: {
      client: null,
      ats: null,
      title: "",
      description: "",
      current_department_id: null,
      created_user_id: null,
      is_private: true,
      sub_category_id: "1",
      priority: "normal",
      related_to_ticket_id: null,
      attachment: [],
      ticketItems: [],
    },
    dialog: false,
    loading: false,
    errors: {},
    is_from_client: false,
  },
  request_details: {
    data: {
      id: "",
      number: "",
      title: "",
      description: "",
      client_id: null,
      client: null,
      current_department_id: "",
      tag_id: null,
      current_department_name: "",
      private: "",
      sub_category_id: 1,
      sub_category_name: "",
      priority: "",
      status: "",
      is_created_by_client: "",
      replays: [],
      created_by_id: "",
      created_by_name: "",
      attachments: [],
    },
    loading: undefined,
  },
  reply: {
    form: {
      ticket_id: "",
      tag_id: null,
      comment: "",
      is_private: true,
      current_department_id: null,
      status: "in_progress",
      attachment: [],
      assign_user: [],
    },
    loading: false,
    dialog: false,
    errors: {},
  },
  dialog_tickets_by_id: false,
  approvment: {
    loading: false,
  },
  addItemsState: {
    replyItems: [],
    loading: false,
  },
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setTicketDetailLoading: (state, action) => {
      state.request_details.loading = action.payload;
    },
    setTickets: (state, action) => {
      state.requests.data = action.payload.data.data;
      state.requests.total = action.payload.data.total;
    },
    setTicket: (state, action) => {
      const { id, name, full_name, email, phone_1, phone_2, notes } =
        action.payload.data[0];
      state.request.form = {
        id,
        name,
        full_name,
        email,
        phone_1,
        phone_2,
        notes,
        password: "",
      };
      state.request.ats = action.payload.data[0].ats;
    },
    setTicketsFiltersDialog: (state, action) => {
      state.requests.dialog = !state.requests.dialog;
    },
    setTicketReplyDialog: (state, { payload }) => {
      state.reply.dialog = !state.reply.dialog;
      state.reply.form.ticket_id = payload ? payload : "";
    },
    setIsFilterCleared: (state, { payload }) => {
      if (payload) {
        state.requests.isFilterCleared = payload;
      }
    },
    resetForm: (state, action) => {
      state.request.form = initialState.request.form;
    },
    setFilters: (state, { payload }) => {
      state.requests.filters = {
        created_user_id: payload.created_user_id || null,
        ...state.requests.filters,
        ...payload,
      };
    },
    setDialog: (state, { payload }) => {
      state.request.dialog = !state.request.dialog;
    },
    setDialogTicketsById: (state, { payload }) => {
      state.dialog_tickets_by_id = !state.dialog_tickets_by_id;
    },
    setTicketDetails: (state, { payload }) => {
      state.request_details.data = { ...payload };
    },
    resetFilters: (state) => {
      state.requests.filters = initialState.requests.filters;
    },
    setTicketForClient: (state, { payload }) => {
      state.request.is_from_client = payload.is_client;
      state.request.form.client = payload.client;
    },
    setReplyItems: (state, action) => {
      state.addItemsState.replyItems = action.payload;
    },
    resetReplyItems: (state, action) => {
      state.addItemsState.replyItems = initialState.addItemsState.replyItems;
    },
  },
});

export const {
  setLoading,
  setTicketDetailLoading,
  setTickets,
  setTicket,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setTicketsFiltersDialog,
  setTicketReplyDialog,
  setTicketDetails,
  setTicketForClient,
  setDialogTicketsById,
  showApprovmentModal,
  setReplyItems,
  resetReplyItems,
  setIsFilterCleared,
} = requestsSlice.actions;

export default requestsSlice.reducer;

//axios
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().requests.requests.filters;
    const filters_ = {
      ...filters,
      department_id: filters.department_id?.id || null,
      related_department_id: filters.related_department_id?.id || null,
      created_user_id: filters.created_user_id?.id || null,
      status: JSON.stringify(filters.status) || null,
      tags_id: JSON.stringify(filters.tags_id?.map((tag) => tag.id)) || null,
    };
    dispatch(setLoading("requests"));
    const res = await requestsApi.index(filters_);
    dispatch(setTickets(res));
    dispatch(setLoading("requests"));
  } catch (err) {
    dispatch(setLoading("requests"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getTicketByid = (id) => async (dispatch, getState) => {
  try {
    dispatch(setTicketDetailLoading(true));
    const res = await ticketsApi.showById(id);
    dispatch(setTicketDetails(res.data.data[0]));
    dispatch(setTicketDetailLoading(false));
  } catch (err) {
    dispatch(setTicketDetailLoading(false));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data, navigate) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("request"));
    const formdata = new FormData();
    if (data.related_to_ticket_id) {
      formdata.append("related_to_ticket_id", data.related_to_ticket_id);
    }

    if (data.tag_id?.id) {
      formdata.append("tag_id", data.tag_id?.id);
    }

    formdata.append("title", data.title);
    formdata.append("description", data.description);
    formdata.append(
      "current_department_id",
      data.current_department_id?.id || null
    );
    formdata.append("is_private", data.is_private);
    formdata.append("priority", data.priority);
    if (data.ticketItems.length > 0) {
      data.ticketItems.forEach((item, index) => {
        formdata.append(`items[${index}][item_id]`, item.item_id);
        formdata.append(`items[${index}][quantity]`, item.quantity);
      });
    }
    const response = await ticketsApi.create(formdata);
    dispatch(setLoading("request"));
    dispatch(
      showNotification({
        type: "success",
        message: "Ticket created successfully",
      })
    );
    dispatch(
      setDialog({
        type: "",
      })
    );
    dispatch(resetForm());
    // dispatch(index());
    navigate(`/requests/${response.data?.data?.[0]?.id}`);
  } catch (err) {
    console.log(err);
    dispatch(setLoading("request"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response?.data?.errors[0],
      })
    );
  }
};
const addItems = (ticketId, data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("addItemsState"));

    await ticketsApi.addItems(data);
    dispatch(
      showNotification({
        type: "success",
        message: "Items added successfully",
      })
    );
    dispatch(getTicketByid(ticketId));
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  } finally {
    dispatch(setLoading("addItemsState"));
  }
};
const remove = (id) => async (dispatch, getState) => {
  try {
    await ticketsApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Ticket deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const reply = (data) => async (dispatch, getState) => {
  try {
    const { id, status } = getState().requests.request_details.data;
    const { department_id } = getState().profile.user.info;
    dispatch(setLoading("reply"));
    const formdata = new FormData();
    formdata.append("ticket_id", data.ticket_id);
    if (data.tag_id?.id) {
      formdata.append("tag_id", data.tag_id.id);
    }
    formdata.append("status", status === "closed" ? "re_open" : data.status);
    formdata.append("comment", data.comment);
    formdata.append(
      "current_department_id",
      data.current_department_id
        ? data.current_department_id?.id
        : department_id
    );
    formdata.append("is_private", data.is_private);
    if (data.attachment.length > 0) {
      data.attachment.forEach((item, index) => {
        formdata.append(`attachment[${index}]`, item);
      });
    }
    if (data.replyItems?.length > 0) {
      data.replyItems.forEach((item, index) => {
        formdata.append(`items[${index}][item_id]`, item.item_id);
        formdata.append(`items[${index}][quantity]`, item.quantity);
      });
    }
    if (data.assign_user.length > 0) {
      let assign_user = [];
      data.assign_user.forEach((item, index) => {
        assign_user.push(item.id);
      });
      formdata.append(`assign_user`, JSON.stringify(assign_user));
    }
    await ticketsApi.reply(formdata);
    dispatch(setLoading("reply"));
    dispatch(resetReplyItems());
    dispatch(
      showNotification({
        type: "success",
        message: "Reply created successfully",
      })
    );
    dispatch(setTicketReplyDialog());
    dispatch(getTicketByid(id));
  } catch (err) {
    console.log(err);
    dispatch(setLoading("reply"));
    dispatch(
      showNotification({
        type: "error",
        message: err?.response?.data?.errors[0],
      })
    );
  }
};
const removeReply = (id) => async (dispatch, getState) => {
  try {
    const ticket = getState().tickets.ticket_details.data;

    await ticketsApi.deleteReplay(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Reply deleted successfully",
      })
    );
    dispatch(getTicketByid(ticket.id));
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const changeReplyPrivacy = (id, status) => async (dispatch, getState) => {
  try {
    const ticket = getState().tickets.ticket_details.data;

    await ticketsApi.changeReplayStatus(id, status);
    dispatch(
      showNotification({
        type: "success",
        message: "Reply Privacy Changed Successfully",
      })
    );
    dispatch(getTicketByid(ticket.id));
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const addApprovment =
  (ticketId, replayId, data) => async (dispatch, getState) => {
    try {
      dispatch(setLoading("approvment"));
      const formdata = new FormData();

      formdata.append("status", data.status);
      formdata.append("comment", data.comment);
      formdata.append("to_department_id", data.to_department.id);
      formdata.append("to_tag_id", data.to_tag ? data.to_tag.id : null);

      await ticketsApi.addApprovment(replayId, formdata);
      dispatch(
        showNotification({
          type: "success",
          message: "add approvment successfully",
        })
      );
      dispatch(getTicketByid(ticketId));
    } catch (err) {
      dispatch(
        showNotification({
          type: "error",
          message: err.response.data.errors[0],
        })
      );
      throw new Error(err);
    } finally {
      dispatch(setLoading("approvment"));
    }
  };

export const Requests = {
  index,
  getTicketByid,
  create,
  remove,
  reply,
  removeReply,
  changeReplyPrivacy,
  addApprovment,
  addItems,
};
