import { createTheme } from "@mui/material/styles";
import componentStyleOverrides from "./compStyleOverride";
import themeTypography from "./typography";

const themeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#435d79",
    },
    secondary: {
      main: "#f50057",
    },
    // warning: {
    //   main: "#ff9800",
    // },
    // info: {
    //   main: "#2196f3",
    // },
    // success: {
    //   main: "#4caf50",
    // },
    // error: {
    //   main: "#f44336",
    // },
  },
  typography: themeTypography,
};

const themes = createTheme(themeOptions);
themes.components = componentStyleOverrides();

export default themes;
