import api from "../../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      params.append(key, p[key]);
    });
  }
  return params;
};

//vdenor , type , brand
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params, type = "vendor") {
    const p = paramsFactory(params);
    return api.get(`/api/v1/${type}?${p}`);
  },
  showById(id, type) {
    return api.get(`/api/v1/${type}/${id}`);
  },
  create(type, data) {
    const form =
      type === "vendor"
        ? { name: data.name, address: data.address, phone: data.phone }
        : { name: data.name, shortcut_name: data.shortcut_name };
    return api.post(`/api/v1/${type}`, form);
  },
  update(type, id, data) {
    const form =
      type === "vendor"
        ? { name: data.name, address: data.address, phone: data.phone }
        : { name: data.name, shortcut_name: data.shortcut_name };
    return api.put(`/api/v1/${type}/${id}`, form);
  },
  delete(type, id) {
    return api.delete(`/api/v1/${type}/${id}`);
  },
};
