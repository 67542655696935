import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";

const constantsApi = factory.get("constants");

const initialState = {
  results: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
    },
    errors: "",
  },
};

export const constantsSlice = createSlice({
  name: "constants",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setResults: (state, action) => {
      state.results.data = action.payload.data.data;
      state.results.total = action.payload.data.total;
      state.results.loading = false;
    },
    setFilters: (state, { payload }) => {
      state.results.filters = {
        ...state.results.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.clients.filters = initialState.clients.filters;
    },
    setErrors: (state, { payload }) => {
      state.results.errors = payload;
    },
  },
});

export const { setLoading, setResults, setFilters, resetFilters, setErrors } =
  constantsSlice.actions;

export default constantsSlice.reducer;

//axios
const index = (type) => async (dispatch, getState) => {
  try {
    const filters = getState().constants.results.filters;
    dispatch(setLoading("results"));
    const res = await constantsApi.index(filters, type);
    dispatch(setResults(res));
  } catch (err) {
    dispatch(setLoading("results"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const showById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("client_profile"));
    const res_profile = await constantsApi.showById(id);
    const res_statistics = await constantsApi.statistics(id);

    dispatch(setLoading("client_profile"));
  } catch (err) {
    dispatch(setLoading("client_profile"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (type, data) => async (dispatch) => {
  try {
    dispatch(setLoading("results"));
    await constantsApi.create(type, data);
    dispatch(setLoading("results"));
    dispatch(
      showNotification({
        type: "success",
        message: "Created successfully",
      })
    );
    dispatch(index(type));
  } catch (err) {
    dispatch(setLoading("results"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    dispatch(setErrors(err.response.data.message));
    throw new Error(err);
  }
};
const update = (type, id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("results"));
    await constantsApi.update(type, id, data);
    dispatch(setLoading("results"));
    dispatch(
      showNotification({
        type: "success",
        message: "Updated successfully",
      })
    );
    dispatch(index(type));
  } catch (err) {
    dispatch(setLoading("results"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    dispatch(setErrors(err.response.data.message));
    throw new Error(err);
  }
};
const Delete = (type, id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("results"));
    await constantsApi.delete(type, id);
    dispatch(setLoading("results"));
    dispatch(
      showNotification({
        type: "success",
        message: "Deleted successfully",
      })
    );
    dispatch(index(type));
  } catch (err) {
    dispatch(setLoading("results"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

export const Constants = {
  index,
  showById,
  create,
  update,
  Delete,
};
