import { Box, Chip, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { Orders } from "../../../app/slices/store/ordersSlice";

export const CabinetItemsColumns = [
  {
    fileld: "ticket_number",
    headerName: "Ticket No.",
    flex: 1,
    renderCell: (params) => {
      return (
        <a
          href={`/tickets/${params.row.ticket_id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip title={params.row.ticket_number}>
            <Typography>{params.row.ticket_number}</Typography>
          </Tooltip>
        </a>
      );
    },
  },
  {
    field: "order_number",
    headerName: "Order Number",
    flex: 1,
    renderCell: (params) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dispatch = useDispatch();
      return (
        <Chip
          label={params.row.order_number}
          role="button"
          onClick={() => dispatch(Orders.getByid(params.row.order_id))}
        />
      );
    },
  },
  {
    field: "item_name",
    headerName: "Item name",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box>
          <Tooltip title={params.row.item_name}>
            <Typography color="initial">{params.row.item_name}</Typography>
          </Tooltip>
          <Typography variant="caption" color="initial">
            {`Count: ${params.row.count}`}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "created_by_name",
    headerName: "Created by",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box>
          <Typography color="initial">{params.row.created_by_name}</Typography>
          <Typography variant="caption" color="initial">
            {dayjs(params.row.created_at).format("YYYY-MM-DD HH:mm")}
          </Typography>
        </Box>
      );
    },
  },
];
