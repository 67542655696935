import "./App.css";
import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import Routes from "./routes";
import NavigationScroll from "./layout/NavigationScroll";
import themes from "./themes";
import NotificationSnackbar from "./components/Notification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("./service-worker.js")
//       .then((registration) => {
//         console.log(
//           "Service Worker registered with scope:",
//           registration.scope
//         );
//       })
//       .catch((error) => {
//         console.error("Service Worker registration failed:", error);
//       });
//   });
// }


function App() {
  LicenseInfo.setLicenseKey(
    "186a4fab9c1d8ad1a6680236cc5071c9Tz01ODIzMCxFPTE3MDYwMDYzODgwMzQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        {/* <ConfirmationDialog /> */}
        <CssBaseline />
        <NotificationSnackbar />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
      <ToastContainer />
    </StyledEngineProvider>
  );
}

export default App;
