import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExportContainer,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import React, { useCallback, useEffect, useState } from "react";
import { renderReportFilter } from "../filters";
import { useDispatch, useSelector } from "react-redux";
import { Reports, setFilters } from "../../../app/slices/reportsSlice";
import dayjs from "dayjs";
import RenderHtml from "../../../components/utils/RenderHtml";

export const ReportsDataGrid = (props) => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);
  const reportName = props.reportName;
  const ExcelExportMenuItem = (props) => {
    const { hideMenu } = props;
    return (
      <MenuItem
        onClick={() => {
          dispatch(Reports.exportExcel(reportName));
          // Hide the export menu after the export
          hideMenu?.();
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <ExcelExportMenuItem />
    </GridToolbarExportContainer>
  );
  const CustomToolBar = (props) => (
    <GridToolbarContainer {...props}>
      <CustomExportButton />
    </GridToolbarContainer>
  );

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
    []
  );
  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds);
  }, []);
  return (
    <Box sx={{ height: "70vh", width: "100%" }}>
      {props.reportName === "departmentTicketsSummary" ? (
        <DataGridPro
          rows={reports.data}
          columns={renderReportFilter(props.reportName).columns}
          columnBuffer={2}
          columnThreshold={2}
          disableColumnMenu
          disableColumnFilter
          rowCount={reports.total}
          loading={reports.loading}
          getDetailPanelContent={
            props.isDetailed && props.reportName === "departmentTicketsSummary"
              ? ({ row }) => (
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={2}
                    my={1}
                    sx={{ width: "100%" }}
                    px={2}
                  >
                    {row.replies?.map((reply, index) => {
                      return (
                        <Grid item xs={3} key={index}>
                          <Card sx={{ height: "25vh", overflow: "scroll" }}>
                            <CardHeader
                              title={
                                <Typography
                                  color={"primary"}
                                  fontWeight={"bold"}
                                >
                                  {reply?.created_by_name}
                                </Typography>
                              }
                              subheader={
                                <Typography variant="caption">
                                  {dayjs(reply?.created_at).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </Typography>
                              }
                            />
                            <CardContent>
                              <RenderHtml
                                sx={{
                                  lineHeight: { xs: "1rem", sm: "1.2" },
                                  fontSize: { xs: ".9rem" },
                                }}
                                component="p"
                                variant="body1"
                                color="text.primary"
                                htmlText={reply?.title}
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                )
              : undefined
          }
          getDetailPanelHeight={
            props.isDetailed
              ? ({ row }) => {
                  return row.replies?.length > 0 ? 300 : 160;
                }
              : undefined
          }
          detailPanelExpandedRowIds={
            props.isDetailed ? detailPanelExpandedRowIds : undefined
          }
          onDetailPanelExpandedRowIdsChange={
            props.isDetailed ? handleDetailPanelExpandedRowIdsChange : undefined
          }
          getRowId={() => Math.floor(Math.random() * 100000)}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolBar,
          }}
        />
      ) : (
        <DataGridPro
          rows={reports.data}
          columns={renderReportFilter(props.reportName).columns}
          columnBuffer={2}
          columnThreshold={2}
          disableColumnMenu
          disableColumnFilter
          rowCount={reports.total}
          loading={reports.loading}
          getRowId={() => Math.floor(Math.random() * 100000)}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolBar,
          }}
        />
      )}
    </Box>
  );
};
