import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";

const cabinetsApi = factory.get("cabinets");

const initialState = {
  cabinets: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
    },
  },
  cabinet: {
    form: {
      name: "",
    },
    dialog: false,
    loading: false,
  },
};

export const cabinetsSlice = createSlice({
  name: "cabinets",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setCabinets: (state, action) => {
      state.cabinets.data = action.payload.data.data;
      state.cabinets.total = action.payload.data.total;
    },
    setFilters: (state, { payload }) => {
      state.cabinets.filters = {
        ...state.cabinets.filters,
        ...payload,
      };
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.cabinet.form = payload;
      } else state.cabinet.form = initialState.cabinet.form;
      state.cabinet.dialog = !state.cabinet.dialog;
    },
    resetForm: (state, action) => {
      state.cabinet.form = initialState.cabinet.form;
    },
    resetFilters: (state) => {
      state.cabinets.filters = initialState.cabinets.filters;
    },
  },
});

export const {
  setLoading,
  setCabinets,
  setFilters,
  setDialog,
  resetForm,
  resetFilters,
} = cabinetsSlice.actions;
export default cabinetsSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().cabinets.cabinets.filters;
    dispatch(setLoading("cabinets"));
    const res = await cabinetsApi.index(filters);
    dispatch(setCabinets(res));
    dispatch(setLoading("cabinets"));
  } catch (err) {
    dispatch(setLoading("cabinets"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("cabinet"));
    await cabinetsApi.create(data);
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        type: "success",
        message: "Cabinet Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const update = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("cabinet"));
    await cabinetsApi.update(id, data);
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        type: "success",
        message: "Cabinet updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("cabinet"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const deleteCabinet = (id) => async (dispatch, getState) => {
  try {
    await cabinetsApi.delete(id);
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
export const Cabinets = {
  index,
  create,
  update,
  deleteCabinet,
};
