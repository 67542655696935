import api from "../../apiConfig";
import paramsFactory from "../../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/cabinet?${p}`);
  },
  showById(id) {
    return api.get(`/api/v1/cabinet/${id}`);
  },
  create(data) {
    return api.post(`/api/v1/cabinet`, data);
  },
  update(id, data) {
    return api.put(`/api/v1/cabinet/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/cabinet/${id}`);
  },
};
