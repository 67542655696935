import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
import { Tickets } from "../ticketsSlice";
import { Items, setTransferItemOrderDialog } from "./itemsSlice";

const ordersApi = factory.get("orders");

const initialState = {
  orders: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
      number: "",
      client_id: "",
      status: "",
      is_my_tickets: "",
      department_id: null,
      priority: "",
      is_private: "",
      action: "",
      is_cleared: null,
    },
    dialog: false,
  },
  order: {
    form: {
      id: "",
      date: dayjs().format("YYYY-MM-DD"),
      attachment: [],
      status: "pending", //pending, approved, canceled
      ticket_id: "",
      ticket_number: "",
      vendor_id: null,
      type: "transfer", //buy, transfer
      from_id: null,
      from_type: "Store", //ATS, User, Store
      to_id: null,
      to_type: "Store", //ATS, User, store
      items: [],
      action: "",
    },
    dialog: false,
    oldDialog: false,
    loading: false,
    errors: {},
  },
  order_invoice: {
    result: {
      id: "",
      order_number: 0,
      ticket_id: "",
      ticket_number: null,
      date: "",
      total_amount: 0,
      status: "",
      type: "",
      created_at: "",
      created_by: "",
      action: "",
      from: null,
      to: { id: "", name: "", type: "" },
      item: [],
    },
    loading: false,
    dialog: false,
  },
  borrowed_items: {
    loading: false,
    data: [],
    dialog: false,
  },
  order_clearance: {
    form: {
      order_id: null,
      cabinet_items: [],
    },
    dialog: false,
    loading: false,
  },
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setOrders: (state, action) => {
      state.orders.data = action.payload.data.data;
      state.orders.total = action.payload.data.total;
    },
    setOrdersFiltersDialog: (state, action) => {
      state.orders.dialog = !state.orders.dialog;
    },
    resetOrder_OrderInvoice: (state, action) => {
      state.order = initialState.order;
      state.order_invoice = initialState.order_invoice;
    },
    setFilters: (state, { payload }) => {
      state.orders.filters = {
        ...state.orders.filters,
        ...payload,
      };
    },
    setDialog: (state, { payload }) => {
      state.order.form = initialState.order.form;
      if (payload?.ticket_id) {
        state.order.form.ticket_id = payload.ticket_id;
        state.order.form.ticket_number = payload.type;
      }
      if (payload?.items) {
        state.order.form.items = payload.items;
      }
      if (payload?.old) {
        state.order.oldDialog = !state.order.oldDialog;
      } else {
        state.order.dialog = !state.order.dialog;
      }
    },
    setOrderInvoice: (state, { payload }) => {
      if (payload) {
        state.order_invoice.result = {
          ...payload,
        };
      }
      state.order_invoice.dialog = !state.order_invoice.dialog;
    },
    resetFilters: (state) => {
      state.orders.filters = initialState.orders.filters;
    },
    setBorrowedItems: (state, { payload }) => {
      state.borrowed_items.data = payload;
      state.borrowed_items.loading = false;
    },
    setBorrowedItemsDialog: (state, { payload }) => {
      state.borrowed_items.dialog = !state.borrowed_items.dialog;
    },
    resetBorrowedItems: (state) => {
      state.borrowed_items = initialState.borrowed_items;
    },
    setOrderClearanceDialog: (state, { payload }) => {
      if (payload?.items) {
        state.order_clearance.form.cabinet_items = payload?.items;
      }
      if (payload?.order_id) {
        state.order_clearance.form.order_id = payload?.order_id;
      } else {
        state.order_clearance.form = initialState.order_clearance.form;
      }
      state.order_clearance.dialog = !state.order_clearance.dialog;
    },
  },
});

export const {
  setLoading,
  setOrders,
  setTicket,
  setFilters,
  resetFilters,
  setDialog,
  resetOrder_OrderInvoice,
  setOrdersFiltersDialog,
  setOrderInvoice,
  setBorrowedItems,
  setBorrowedItemsDialog,
  resetBorrowedItems,
  setOrderClearanceDialog,
} = ordersSlice.actions;

export default ordersSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().orders.orders.filters;
    dispatch(setLoading("orders"));
    const res = await ordersApi.index(filters);
    dispatch(setOrders(res));
    dispatch(setLoading("orders"));
  } catch (err) {
    dispatch(setLoading("orders"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response?.data?.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getByid = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("order_invoice"));
    const res = await ordersApi.showById(id);
    dispatch(setOrderInvoice(res.data.data[0]));
  } catch (err) {
    dispatch(setLoading("order_invoice"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getClientBrrowItems = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("borrowed_items"));
    const res = await ordersApi.getClientBrrowItems(id);
    dispatch(setBorrowedItems(res.data.data));
  } catch (err) {
    dispatch(setLoading("borrowed_items"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create =
  (data, isOrderPage = true) =>
  async (dispatch, getState) => {
    try {
      const old = getState().orders.order.oldDialog;
      dispatch(setLoading("order"));
      const formdata = new FormData();
      if (data.from_id?.id) {
        formdata.append("from_id", data.from_id.id);
      }
      if (data.action) {
        formdata.append("action", data.action);
      }
      if (data.to_id?.id) {
        formdata.append("to_id", data.to_id?.id);
      }
      if (data.type === "buy") {
        formdata.append("vendor_id", data.vendor_id?.id);
      }
      if (data.from_type) {
        formdata.append("from_type", data.from_type);
      }
      formdata.append("to_type", data.to_type);
      formdata.append("type", data.type);
      formdata.append("status", data.status);
      formdata.append("date", data.date);
      formdata.append("ticket_id", data.ticket_id);
      formdata.append("items", JSON.stringify(data.items));
      if (data.ticket_number?.id) {
        formdata.append("ticket_id", data.ticket_number.id);
      }
      if (data.attachment.length > 0) {
        formdata.append(`attachment`, data.attachment[0]);
      }
      await ordersApi.create(formdata);
      dispatch(setLoading("order"));
      dispatch(
        showNotification({
          type: "success",
          message: "Order created successfully",
        })
      );
      if (isOrderPage) {
        old
          ? dispatch(
              setDialog({
                type: "",
                old: true,
              })
            )
          : dispatch(
              setDialog({
                type: "",
              })
            );
        // dispatch(resetForm());
        //fetch ticket by id
        if (data.ticket_number !== "") {
          dispatch(Tickets.getTicketByid(data.ticket_id));
        } else if (data.ticket_id) {
          dispatch(Tickets.getTicketByid(data.ticket_id));
        } else {
          dispatch(index());
        }
      } else {
        dispatch(setTransferItemOrderDialog());
        dispatch(Items.indexStoreItemsLocation());
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading("order"));
      dispatch(
        showNotification({
          type: "error",
          message: err?.response?.data.errors[0],
        })
      );
      // throw new Error(err);
    }
  };
const remove = (id) => async (dispatch, getState) => {
  try {
    await ordersApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "order deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const setInService = (id) => async (dispatch, getState) => {
  try {
    await ordersApi.setInService(id);
    dispatch(setLoading("order_invoice"));
    const res = await ordersApi.showById(id);
    dispatch(setOrderInvoice(res.data.data[0]));
    dispatch(setOrderInvoice(res.data.data[0]));
    dispatch(
      showNotification({
        type: "success",
        message: "Order set in service operation success",
      })
    );
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
  }
};
const clearOrder =
  (data, indexOrders = true, ticketId) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading("order_clearance"));
      const formdata = new FormData();
      formdata.append("order_id", data.order_id);
      formdata.append("cabinet_items", JSON.stringify(data.cabinet_items));
      await ordersApi.orderClearance(formdata);
      dispatch(setLoading("order_clearance"));
      dispatch(
        showNotification({
          type: "success",
          message: "Order cleared successfully",
        })
      );
      dispatch(setOrderClearanceDialog());
      dispatch(setOrderInvoice());
      indexOrders && dispatch(index());
      !indexOrders && ticketId && dispatch(Tickets.getTicketByid(ticketId));
    } catch (err) {
      dispatch(setLoading("order_clearance"));
      console.log(err);
      dispatch(
        showNotification({
          type: "error",
          message: err?.response?.data.errors[0],
        })
      );
    }
  };
export const Orders = {
  index,
  getByid,
  create,
  remove,
  getClientBrrowItems,
  setInService,
  clearOrder,
};
