import {
  Typography,
  Box,
  Stack,
  Chip,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Orders,
  setOrderClearanceDialog,
  setOrderInvoice,
} from "../../app/slices/store/ordersSlice";
import { MdOutlineVerified } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { setTransferItemOrderDialog } from "../../app/slices/store/itemsSlice";
import OrderClearanceDialog from "./OrderClearanceDialog";
const OrdersInvoice = (props) => {
  const {
    order_invoice: { result, dialog },
    order_clearance,
  } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "name",
      headerName: "Item Name",
      flex: 1,
      sortable: false,
      headerClassName: "item-theme-header",
    },
    {
      field: "cost_price",
      headerName: "Price",
      flex: 1,
      sortable: false,
      headerClassName: "item-theme-header",
    },
    {
      field: "count",
      headerName: "Quantity",
      flex: 1,
      sortable: false,
      headerClassName: "quantity-theme-header",
    },
    {
      field: "sn",
      headerName: "SN",
      flex: 1,
      sortable: false,
      headerClassName: "quantity-theme-header",
      renderCell: (params) => {
        return (
          params.row.sn.length > 0 &&
          params.row.sn.map((item, index) => <Chip key={index} label={item} />)
        );
      },
    },
    {
      field: "in_service",
      HeaderName: "Service Status",
      minWidth: 80,
      sortable: false,
      headerClassName: "quantity-theme-header",
      align: "center",
      renderCell: (params) => {
        return params.row.in_service ? (
          <MdOutlineVerified size={25} color="#1d4966" />
        ) : (
          <IoCloseCircleOutline size={25} />
        );
      },
    },
  ];
  const InvoiceHeader = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        color={"white"}
      >
        {/* title */}
        <Typography variant="h2" color={"#329c9e"} fontWeight={"bold"}>
          INVOICE
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(Orders.setInService(result.id));
          }}
        >
          Set in service
        </Button>
        <Stack direction="column">
          {/* invoice no. */}
          <Stack direction="row" spacing={7}>
            <Typography variant="body1" fontWeight={"bold"}>
              Invoice No:
            </Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              #{result.order_number}
            </Typography>
          </Stack>
          {/* invoice date */}
          <Stack direction="row" spacing={5}>
            <Typography variant="body1" fontWeight={"bold"}>
              Invoice Date:
            </Typography>

            <Typography variant="body1" fontWeight={"bold"}>
              {dayjs(result.created_at).format("YYYY/MM/DD")}
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Typography variant="body1" fontWeight={"bold"}>
              Ticket Number:
            </Typography>
            <a
              href={`/tickets/${result.ticket_id}`}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
              }}
            >
              {result.ticket_number}
            </a>
          </Stack>
          <Stack direction="row" spacing={7}>
            <Typography variant="body1" fontWeight={"bold"}>
              Created by:
            </Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {result.created_by}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={12}>
            <Typography variant="body1" fontWeight={"bold"}>
              Action
            </Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {result.action}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  };

  const DestinationInfo = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        color={"white"}
      >
        {/*  from. */}
        {result.from && (
          <Stack direction="column">
            <Typography variant="h6" color={"#329c9e"}>
              From : {result.from?.type}
            </Typography>
            <Stack display={"flex"} direction="column">
              <Typography variant="h5" fontWeight={"bold"}>
                {result.from.name}
              </Typography>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <BsTelephone /> {result.from.phone}
              </Typography>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <AiOutlineMail />
                {result.from.email}
              </Typography>
              {/* <Typography
                variant="body1"
                fontWeight={"bold"}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <BiMap /> Iraq, Baghdad - Al-Karada
              </Typography> */}
            </Stack>
          </Stack>
        )}

        <Typography
          variant="h4"
          color={"#329c9e"}
          display={"flex"}
          alignItems={"center"}
          fontWeight={"bold"}
        >
          {result.type.toUpperCase()}
        </Typography>

        {/*  to */}
        <Stack direction="column">
          <Typography variant="h6" color={"#329c9e"}>
            To : {result.to?.type}
          </Typography>
          <Stack display={"flex"} direction="column">
            <Typography variant="h5" fontWeight={"bold"}>
              {result.to?.name}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"bold"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <BsTelephone /> {result.to?.phone}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"bold"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <AiOutlineMail /> {result.to?.email}
            </Typography>
            {/* <Typography
              variant="body1"
              fontWeight={"bold"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <BiMap /> Iraq, Baghdad - Al-Karada
            </Typography> */}
          </Stack>
        </Stack>
      </Box>
    );
  };
  const InvoiceItems = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        // bgcolor={"white"}
        borderColor={"transparent"}
        border={0}
        // borderWidth={2}
        // borderStyle={"solid"}
        px={4}
        py={2}
        mt={-9}
        sx={{
          "& .item-theme-header": {
            backgroundColor: "#255f85",
            color: "white",
          },
          "& .quantity-theme-header": {
            backgroundColor: "#1d4966",
            color: "white",
          },
        }}
      >
        <DataGridPro
          sx={{ height: "40vh", width: "100%", borderRadius: 4, border: 0 }}
          rows={result.item}
          columns={columns}
          pageSize={15}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          disableColumnFilter
          disableDensitySelector
          disableColumnReorder
          disableColumnResize
          disableMultipleColumnsSorting
          disableMultipleSelection
          disableExtendRowFullWidth
          disableRowExpandable
          hideFooterPagination
          hideFooterSelectedRowCount
          hideFooterRowCount
          hideFooter
        />
      </Box>
    );
  };
  const handleDialog = () => {
    dispatch(setOrderInvoice());
  };
  return (
    <Dialog
      open={dialog}
      keepMounted
      fullWidth={true}
      maxWidth={"lg"}
      onClose={() => {
        handleDialog();
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box display={"flex"} flexDirection={"column"}>
          <Stack
            px={4}
            py={2}
            pb={9}
            display={"flex"}
            gap={2}
            direction="column"
            bgcolor="#1b2e3f"
          >
            <InvoiceHeader />
            <DestinationInfo />
          </Stack>
          <InvoiceItems />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={
            !(result.type === "transfer" && result.action === "receivable")
          }
          onClick={() =>
            dispatch(
              setOrderClearanceDialog({
                items: result.item,
                order_id: result.id,
              })
            )
          }
        >
          Order Clearance
        </Button>
      </DialogActions>
      {order_clearance.dialog && (
        <OrderClearanceDialog ticketId={props.ticketId} />
      )}
    </Dialog>
  );
};

export default OrdersInvoice;
