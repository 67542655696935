import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { useEffect, forwardRef, Fragment, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { factory } from "../../api/apiFactory";
const employeesApi = factory.get("employees");

export const SelectEmployee = forwardRef((props, ref) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchEmployee = (search = "") => {
    setLoading(true);
    employeesApi
      .list({ skip: 0, take: 40, is_active: 1, search: search })
      .then((res) => {
        setOptions(res.data.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchEmployee(search);
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <Autocomplete
      {...props}
      ref={ref}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id || ""}
      getOptionLabel={(option) => `${option.name} `}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={props.helpertext}
          label={props.label || "Employee"}
          disabled={props.disabled}
          size="small"
          onChange={(e, value) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
});
