import { Box, Chip, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";

export const TicketsDurationColumns = [
  {
    fileld: "ticket_number",
    headerName: "Ticket No.",
    flex: 1,
    renderCell: (params) => {
      return (
        <a
          href={`/tickets/${params.row.ticket_id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip title={params.row.ticket_number}>
            <Typography>{params.row.ticket_number}</Typography>
          </Tooltip>
        </a>
      );
    },
  },
  {
    field: "title",
    headerName: "Title",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box>
          <Tooltip title={params.row.ticket_title}>
            <Typography color="initial">{params.row.ticket_title}</Typography>
          </Tooltip>
          <Typography variant="caption" color="initial">
            {`${params.row.replys_count} ${
              params.row.replys_count > 1 ? "Replies" : "Reply"
            }`}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "created_by_name",
    headerName: "Created by",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box>
          <Typography color="initial">{params.row.created_by_name}</Typography>
          <Typography variant="caption" color="initial">
            {dayjs(params.row.created_at).format("YYYY-MM-DD HH:mm")}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "client_name",
    headerName: "Client",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box>
          <Typography color="initial">
            {params.row.client_name || "N/A"}
          </Typography>
          <Typography variant="caption" color="initial">
            {params.row.ats ? params.row.ats.name : "N/A"}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "created_by_department_name",
    headerName: "Opened by department",
    flex: 1,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.created_by_department_name}>
          <Typography color="initial">
            {params.row.created_by_department_name}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "current_department_name",
    headerName: "Current department",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box>
          <Typography color="initial">
            {params.row.current_department_name}
          </Typography>
          <Typography variant="caption" color="initial">
            {params.row.current_tag}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return (
        <Chip
          label={params.row.status.toUpperCase().replace("_", " ")}
          size="small"
          color={
            params.row.status === "open" || params.row.status === "re_open"
              ? "success"
              : params.row.status === "in_progress"
              ? "warning"
              : "error"
          }
          sx={{ mb: "3px" }}
        />
      );
    },
  },
  {
    field: "updated_at",
    headerName: "Updated at",
    flex: 1.5,
    renderCell: (params) => {
      return (
        <Typography color="initial">
          {dayjs(params.row.updated_at).format("YYYY-MM-DD HH:mm")}
        </Typography>
      );
    },
  },
  {
    field: "duration_in_minutes",
    headerName: "Duration",
    flex: 1.5,
    renderCell: (params) => {
      return (
        <Box>
          <Typography color={params.row.duration_in_minutes > 0 && "green"}>
            {`${params.row.duration_in_minutes} minutes (${Math.floor(
              params.row.duration_in_minutes / 60
            )}h ${params.row.duration_in_minutes % 60}m)`}
          </Typography>
          <Typography>{`Resolved by: ${
            params.row.resolved_by_user || "N/A"
          }`}</Typography>
        </Box>
      );
    },
  },
];
