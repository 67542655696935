import api from "../apiConfig";
import paramsFactory from "../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/ticket-has-items?${p}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
