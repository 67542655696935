import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      params.append(key, p[key]);
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/ats_device?${p}`);
  },
  showById(id) {
    return api.get(`/api/v1/ats_device/${id}`);
  },
  create(data) {
    return api.post(`/api/v1/ats_device`, data);
  },
  update(id, data) {
    return api.put(`/api/v1/ats_device/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/ats_device/${id}`);
  },
};
