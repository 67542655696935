import { TicketsColumns } from "../Columns/TicketsColumns";
import { TicketsDurationColumns } from "../Columns/TicketsDurationColumns";
import CabinetItemsFilters from "./CabinetItemsFilters";
import TicketsDurationsSummaryFilters from "./TicketsDurationsSummaryFilters";
import TicketsFilters from "./TicketsFilters";
import { CabinetItemsColumns } from "../Columns/CabinetItemsColumns";
export const renderReportFilter = (reportName) => {
  switch (reportName) {
    case "departmentTicketsSummary":
      return {
        filter: <TicketsFilters />,
        columns: TicketsColumns,
      };
    case "ticketsDurationsSummary":
      return {
        filter: <TicketsDurationsSummaryFilters />,
        columns: TicketsDurationColumns,
      };
    case "cabinetItemsReport":
      return {
        filter: <CabinetItemsFilters />,
        columns: CabinetItemsColumns,
      };
    default:
      return {
        filter: <TicketsFilters />,
        columns: TicketsColumns,
      };
  }
};
