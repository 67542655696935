import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Orders,
  setOrderClearanceDialog,
} from "../../app/slices/store/ordersSlice";
import SelectCabinet from "../Selects/SelectCabinet";
import { LoadingButton } from "@mui/lab";
import { IoIosAddCircle } from "react-icons/io";
const OrderClearanceDialog = (props) => {
  const dispatch = useDispatch();
  const { order_clearance } = useSelector((state) => state.orders);
  const {
    control,
    reset,
    watch,
    register,
    clearErrors,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: order_clearance.form,
    reValidateMode: ["onChange", "onBlur"],
  });
  const [items, setItems] = useState(
    order_clearance.form.cabinet_items.map((item) => ({
      item_id: item.item_id,
      sn: [],
      note: "",
      cost_price: 0,
      count: [],
      damaged_count: 0,
      cabinets: [],
    }))
  );
  const [cabinetInputs, setCabinetInputs] = useState(
    order_clearance.form.cabinet_items.map(() => 1)
  );
  const onSubmit = (data) => {
    const transformedData = data.cabinet_items.map((item) => {
      const cabinets = item.cabinets.map((cabinet, index) => {
        return {
          cabinet_id: cabinet?.id || null,
          count: item.count[index] || 0,
        };
      });

      // Add default cabinet if cabinets array is empty or if there's a count with no cabinet selected
      if (
        cabinets.length === 0 ||
        cabinets.some(
          (cabinet) => cabinet.count > 0 && cabinet.cabinet_id === null
        )
      ) {
        cabinets.push({ cabinet_id: null, count: 0 });
      }

      return {
        item_id: item.item_id,
        // cost_price: item.cost_price,
        damaged_count: item.damaged_count,
        // sn: item.sn,
        cabinets,
      };
    });
    data.cabinet_items = transformedData;
    if (props.ticketId) {
      dispatch(Orders.clearOrder(data, false, props.ticketId));
    } else {
      dispatch(Orders.clearOrder(data));
    }
  };

  const onError = () => console.log(errors);
  const handlDialog = () => {
    dispatch(setOrderClearanceDialog());
  };

  return (
    <Dialog
      open={order_clearance.dialog}
      keepMounted
      fullWidth={true}
      dir="ltr"
      maxWidth={"md"}
      onClose={() => {
        handlDialog();
      }}
    >
      <DialogTitle
        children={
          <Typography color={"primary"} variant="h6" fontWeight={"bold"}>
            Clear Order
          </Typography>
        }
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Grid container spacing={1} mt={1}>
                <Grid item xs={12}>
                  <Typography>Items</Typography>
                  {order_clearance.form.cabinet_items.map((item, index) => {
                    return (
                      <Grid container spacing={1} key={index} my={1}>
                        <Grid item xs={4}>
                          <TextField
                            disabled
                            value={item.name}
                            label="Item name"
                            fullWidth
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            disabled
                            label={"Count"}
                            size="small"
                            fullWidth
                            value={item.count}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Damaged quantity"
                            size="small"
                            type="number"
                            fullWidth
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].damaged_count = parseInt(
                                e.target.value
                              );
                              setValue("cabinet_items", newItems);
                            }}
                          />
                        </Grid>
                        {[...Array(cabinetInputs[index])].map(
                          (_, inputIndex) => (
                            <React.Fragment key={inputIndex}>
                              <Grid item xs={6}>
                                <SelectCabinet
                                  filterSelectedOptions
                                  label="Select Cabinet"
                                  required={
                                    watch("cabinet_items")[index]?.count[
                                      index
                                    ] > 0
                                  }
                                  onChange={(e, newValue) => {
                                    const newItems = [...items];
                                    if (
                                      newItems[index].cabinets.length >
                                      inputIndex
                                    ) {
                                      newItems[index].cabinets[inputIndex] =
                                        newValue;
                                    } else {
                                      newItems[index].cabinets.push(newValue);
                                    }
                                    setItems(newItems);
                                    setValue("cabinet_items", newItems);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  type="number"
                                  label={"Used quantity"}
                                  required
                                  fullWidth
                                  size="small"
                                  onChange={(e) => {
                                    const newItems = [...items];
                                    if (
                                      newItems[index].count.length > inputIndex
                                    ) {
                                      newItems[index].count[inputIndex] =
                                        parseInt(e.target.value);
                                    } else {
                                      newItems[index].count.push(
                                        parseInt(e.target.value)
                                      );
                                    }
                                    setItems(newItems);
                                    setValue("cabinet_items", newItems);
                                  }}
                                />
                              </Grid>
                            </React.Fragment>
                          )
                        )}
                        <Grid item xs={11}></Grid>
                        <Grid item xs={1}>
                          <Tooltip title="Add cabinet">
                            <IconButton
                              onClick={() => {
                                const newCabinetInputs = [...cabinetInputs];
                                newCabinetInputs[index] += 1;
                                setCabinetInputs(newCabinetInputs);
                              }}
                            >
                              <IoIosAddCircle size={25} color="green" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider sx={{ my: 1 }}></Divider>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <DialogActions dir="ltr">
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={order_clearance.loading}
                >
                  Clear Order
                </LoadingButton>
              </DialogActions>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OrderClearanceDialog;
