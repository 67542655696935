import React, { useEffect } from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";

import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../../app/slices/appSlice";
import ProfileMenu from "./ProfileMenu";
import { useLocation } from "react-router-dom";

const Appbar = () => {
  const location = useLocation();

  const drawerWidth = 210;
  const theme = useTheme();

  const leftDrawerOpened = useSelector((state) => state.app.is_drawer_opened);
  const dispatch = useDispatch();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    }),
  }));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        elevation={0}
        open={leftDrawerOpened}
        sx={{
          bgcolor: theme.palette.primary.main,
        }}
      >
        <Toolbar>
          <IconButton
            onClick={() => dispatch(setDrawer())}
            color="inherit"
            aria-label="open drawer"
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1, fontSize: { xs: "1rem", sm: "1.5rem" } }}
          >
            Ticketing System
          </Typography>
          <ProfileMenu />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Appbar;
