import { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@mui/material";

//styles and theme
import { useTheme } from "@mui/material/styles";
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from "@mui/material/Collapse";
import Can from "../../../helpers/Can";
//icons
import MailIcon from "@mui/icons-material/Mail";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AiOutlineHome } from "react-icons/ai";
import { BsBuildings } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineTicket } from "react-icons/hi";
import { FiUsers } from "react-icons/fi";
import {
  TbBuildingBroadcastTower,
  TbReportSearch,
  TbServer2,
} from "react-icons/tb";
//store
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../../app/slices/appSlice";
import { RiFolderUserLine, RiShieldUserLine } from "react-icons/ri";
import { BsBoxSeam } from "react-icons/bs";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { BiStore } from "react-icons/bi";
import { TbFileInvoice } from "react-icons/tb";
import { TiTags } from "react-icons/ti";
import { IoLocationOutline } from "react-icons/io5";
import CategoryIcon from "@mui/icons-material/Category";
import { MdHistory } from "react-icons/md";
import { MdOutlineRequestQuote } from "react-icons/md";
const SideBar = ({ totalTickets = 0, totalRequests = 0 }) => {
  const location = useLocation();
  const {
    user: {
      info: { permissions },
    },
  } = useSelector((state) => state.profile);
  //uses
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //constants
  const drawerWidth = 220;

  //states
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  // const [mobileOpen, setMobileOpen] = useState(false);

  //selectors and media queries
  const drawerOpen = useSelector((state) => state.app.is_drawer_opened);
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const links = [
    {
      id: 1,
      name: "Home",
      icon: <AiOutlineHome size={20} />,
      path: "/",
      sub: [],
      can: "client_view",
    },
    {
      id: 2,
      name: "Clients",
      icon: <FiUsers size={20} />,
      path: "/clients",
      sub: [],
      can: "client_view",
    },
    {
      id: 3,
      name: "Towers",
      icon: <TbBuildingBroadcastTower size={20} />,
      path: "/ats",
      sub: [],
      can: "tower_view",
    },
    {
      id: 3,
      name: "Tickets",
      icon: <HiOutlineTicket size={20} />,
      path: "/tickets",
      sub: [],
      can: "ticket_view",
    },
    {
      id: 7,
      name: "Requests",
      icon: <MdOutlineRequestQuote size={20} />,
      path: "/requests",
      sub: [],
      can: "ticket_view",
    },
    {
      id: 4,
      name: "Employees",
      icon: <RiFolderUserLine size={20} />,
      path: "/employees",
      sub: [],
      can: "user_manage",
    },
    {
      id: 6,
      name: "Department Tags",
      icon: <TiTags size={20} />,
      path: "/tags",
      sub: [],
      can: "user_manage",
    },
    {
      id: 7,
      name: "Store",
      icon: <BiStore size={20} />,
      path: "/store",
      sub: [
        {
          id: 1,
          name: "Constants",
          icon: <AiOutlineCodeSandbox />,
          path: "/store/constants",
          can: "store_manage",
        },
        {
          id: 2,
          name: "Items",
          icon: <BsBoxSeam />,
          path: "/store/items",
          can: "store_manage",
        },
        {
          id: 3,
          name: "Items Location",
          icon: <IoLocationOutline />,
          path: "/store/items-location",
          can: "store_manage",
        },
        {
          id: 4,
          name: "Orders",
          icon: <TbFileInvoice />,
          path: "/store/orders",
          can: "order_view",
        },
        {
          id: 5,
          name: "Cabinets",
          icon: <TbServer2 />,
          path: "/store/cabinets",
          can: "cabinet_manage",
        },
        {
          id: 6,
          name: "Items history",
          icon: <MdHistory />,
          path: "/store/order-items",
          can: "order_view",
        },
      ],
    },
    {
      id: 8,
      name: "Companies",
      icon: <BsBuildings size={20} />,
      path: "/companies",
      sub: [],
      can: "isp_company_view",
    },
    {
      id: 5,
      name: "Categories",
      icon: <CategoryIcon size={20} />,
      path: "/categories",
      sub: [],
      can: "user_manage",
    },
    {
      id: 5,
      name: "Roles & Permissions",
      icon: <RiShieldUserLine size={20} />,
      path: "/roles-permissions",
      sub: [],
      can: "role_view",
    },
    {
      id: 6,
      name: "Reports",
      icon: <TbReportSearch size={20} />,
      path: "/reports",
      sub: [],
      can: "report_view",
    },
  ];

  const DrawerSubLinks = (link, index) => {
    useEffect(() => {
      if (link.sub.map((item) => item.path).includes(location.pathname)) {
        setOpen(true);
      }
    }, []);
    return link.sub.length > 0 ? (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true} component="div" disablePadding>
          {link.sub.map(
            (item, index) =>
              Can(item.can) && (
                <ListItemButton
                  selected={item.path === location.pathname}
                  key={index}
                  sx={{ pl: 3 }}
                  onClick={() => {
                    navigate(item.path);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "25px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              )
          )}
        </List>
      </Collapse>
    ) : null;
  };

  const drawerLinks = (
    <Box m={1}>
      <List
        dense={false}
        sx={{
          "& .MuiListItem-root:hover": {
            backgroundColor: theme.palette.primary.light,
            color: "white",
            borderRadius: "8px",
          },
          "& .MuiListItemButton-root.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            borderRadius: "8px",
            fontWeight: "bold",
          },
          "& .Mui-selected:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            borderRadius: "8px",
            fontWeight: "bold",
          },
          //style for mui selected icon color
          "& .Mui-selected .MuiListItemIcon-root": {
            color: "white",
          },
        }}
      >
        {links
          // .filter((o) => o.roles.includes(roles[0]?.name))
          .map((item, index) => (
            <div key={index}>
              {item.sub.length == 0 && Can(item.can) ? (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    key={index}
                    selected={item.path === location.pathname}
                    onClick={() => {
                      navigate(item.path);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "0.8rem",
                        // padding: "0px 15px 0px 0px",
                      }}
                      disableTypography={true}
                      primary={item.name}
                    />
                    {item.name === "Tickets" ? (
                      <Badge
                        badgeContent={totalTickets}
                        color="info"
                        max={10000}
                        sx={{
                          "& .MuiBadge-badge": {
                            fontSize: "0.8rem",
                            height: "1.8vh",
                            minWidth: "1.8vh",
                          },
                          marginInlineEnd: 1,
                        }}
                      ></Badge>
                    ) : (
                      item.name === "Requests" && (
                        <Badge
                          badgeContent={totalRequests}
                          color="info"
                          sx={{
                            "& .MuiBadge-badge": {
                              fontSize: "0.8rem",
                              height: "1.8vh",
                              minWidth: "1.8vh",
                            },
                            marginInlineEnd: 1,
                          }}
                          max={10000}
                        ></Badge>
                      )
                    )}
                  </ListItemButton>
                </ListItem>
              ) : item.sub.length > 0 && item.sub.some((o) => Can(o.can)) ? (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    key={index}
                    selected={item.path === location.pathname}
                    onClick={() => {
                      if (item.sub.length == 0) navigate(item.path);
                      if (index === selectedID) {
                        setOpen(!open);
                      } else {
                        setOpen(true);
                        setSelectedID(index);
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "25px",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "0.8rem",
                        // padding: "0px 15px 0px 0px",
                      }}
                      disableTypography={true}
                      primary={item.name}
                    />
                    {item.sub.length > 0 ? (
                      open ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : (
                      ""
                    )}
                  </ListItemButton>
                </ListItem>
              ) : null}
              {DrawerSubLinks(item, index)}
            </div>
          ))}
      </List>
    </Box>
  );
  const drawerViewAndScroll = (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            mx: "auto",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../../../assets/images/horizontal_logo.png")}
            height={75}
            alt="logo"
          />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={
            {
              // height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
              // paddingLeft: "10px",
              // paddingRight: "10px",
            }
          }
        >
          {drawerLinks}
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>{drawerLinks}</Box>
      </MobileView>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: drawerOpen ? drawerWidth : "0px" }}
      aria-label="sidebar"
    >
      {permissions.length > 0 && (
        <Drawer
          variant={matchUpMd ? "persistent" : "temporary"}
          open={drawerOpen}
          onClose={() => dispatch(setDrawer())}
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              borderRight: "none",
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {drawerViewAndScroll}
        </Drawer>
      )}
    </Box>
  );
};

export default SideBar;
