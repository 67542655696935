export default function componentStyleOverrides(theme) {
  const border_shadow = {
    borderRadius: 10,
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };
  return {
    MuiTypography: {
      defaultProps: {
        fontFamily: "Montserrat",
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: border_shadow.borderRadius,
          boxShadow: border_shadow.boxShadow,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: border_shadow.borderRadius,
          boxShadow: border_shadow.boxShadow,
        },
      },
    },
  };
}
