import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { factory } from "../../api/apiFactory";

const cabinetsApi = factory.get("cabinets");

export const SelectCabinet = (props) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchCabinets = (search = "") => {
    setLoading(true);
    cabinetsApi.index({ skip: 0, take: 5, search: search }).then((res) => {
      setOptions(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchCabinets(search);
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);
  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id || ""}
      getOptionLabel={(option) => `${option.name} `}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={props.helpertext}
          label={props.label || "Cabinets"}
          required={props.required}
          size="small"
          onChange={(e, value) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectCabinet;
