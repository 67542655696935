import { Box } from "@mui/material";

/**
 * Render text html into dom.
 */
const RenderHtml = ({
  component = "div",
  color = "text.primary",
  htmlText,
  ...props
}) => {
  return (
    <Box
      component={component}
      color={color}
      {...props}
      dangerouslySetInnerHTML={{ __html: htmlText }}
    />
  );
};

export default RenderHtml;
