import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";

const authApi = factory.get("auth");

const initialState = {
  user: {
    loading: false,
    info: {
      id: "",
      full_name: "",
      first_name: "",
      email: "",
      department_id: 1,
      department_name: "",
      active: true,
      gender: "",
      role: "",
      notify: false,
      permissions: [],
      profile_pic: null,
      show_departments: [],
      show_tags: [],
      ticket_notification: false,
    },
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setProfile: (state, { payload }) => {
      const permissions = payload.permissions.map((p) => p.name);

      state.user.info = { ...payload, permissions };
    },
    setProfilePicture: (state, { payload }) => {
      state.user.info.profile_pic = payload;
    },
    setNotify: (state, { payload }) => {
      state.user.info.notify = payload;
    },
    setTicketNotification: (state, { payload }) => {
      state.user.info.ticket_notification = payload;
    },
  },
});

export const {
  setProfile,
  setProfilePicture,
  setLoading,
  setNotify,
  setTicketNotification,
} = profileSlice.actions;

export default profileSlice.reducer;

//axios
export const Profile = {
  myInfo: (data) => async (dispatch) => {
    try {
      dispatch(setLoading("user"));
      const res = await authApi.profile();
      dispatch(setProfile(res.data.data[0]));
      dispatch(setLoading("user"));
    } catch (err) {
      dispatch(setLoading("user"));
      throw err;
    }
  },
  profilePicture: (profile_pic) => async (dispatch) => {
    try {
      dispatch(setLoading("user"));
      const formData = new FormData();
      formData.append("profile_pic", profile_pic);
      formData.append("mobile", true);
      const res = await authApi.profilePicture(formData);

      dispatch(setProfilePicture(res.data.data[0].profile_pic.path));

      dispatch(
        showNotification({
          message: res.data.message,
          type: "success",
        })
      );
      dispatch(setLoading("user"));
      return {
        status: true,
      };
    } catch (err) {
      dispatch(setLoading("user"));
      throw new Error(err);
    }
  },
  toggleNotify: (data) => async (dispatch) => {
    try {
      dispatch(setLoading("user"));
      const res = await authApi.toggleNotify(data);
      dispatch(setNotify(res.data.data[0]?.notify));
      dispatch(setLoading("user"));
      dispatch(
        showNotification({
          message: res.data.message,
          type: "success",
        })
      );
    } catch (err) {
      dispatch(setLoading("user"));
      throw err;
    }
  },
  togglePushNotification: (data) => async (dispatch) => {
    try {
      dispatch(setLoading("user"));
      const res = await authApi.togglePushNotification(data);
      dispatch(setTicketNotification(res.data.data[0]?.ticket_notification));
      dispatch(setLoading("user"));
      dispatch(
        showNotification({
          message: res.data.message,
          type: "success",
        })
      );
    } catch (err) {
      dispatch(setLoading("user"));
      throw err;
    }
  },
};
