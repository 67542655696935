import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";

const companiesApi = factory.get("companies");

const initialState = {
  companies: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
    },
    dialog: false,
  },
  company: {
    form: {
      id: "",
      name: "",
      note: "",
    },
    dialog: false,
    loading: false,
    errors: {},
  },
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setResults: (state, action) => {
      state.companies.data = action.payload.data.data;
      state.companies.total = action.payload.data.total;
    },
    resetForm: (state, action) => {
      state.company.form = initialState.company.form;
    },
    setFilters: (state, { payload }) => {
      state.companies.filters = {
        ...state.companies.filters,
        ...payload,
      };
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.company.form.id = payload.id;
        state.company.form.name = payload.name;
        state.company.form.note = payload.note;
      } else state.company.form = initialState.company.form;
      state.company.dialog = !state.company.dialog;
    },
    resetFilters: (state) => {
      state.companies.filters = initialState.companies.filters;
    },
  },
});

export const {
  setLoading,
  setResults,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
} = companiesSlice.actions;

export default companiesSlice.reducer;

//axios
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().companies.companies.filters;
    dispatch(setLoading("companies"));
    const res = await companiesApi.index(filters);
    dispatch(setResults(res));
    dispatch(setLoading("companies"));
  } catch (err) {
    dispatch(setLoading("companies"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("company"));
    await companiesApi.create(data);
    dispatch(setLoading("company"));
    dispatch(
      showNotification({
        type: "success",
        message: "Company Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("company"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const update = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("company"));
    const request = {
      id: data.id,
      name: data.name,
      note: data.note,
    };
    await companiesApi.update(request);
    dispatch(setLoading("company"));
    dispatch(
      showNotification({
        type: "success",
        message: "Company updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("company"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const deleteCompany = (id) => async (dispatch, getState) => {
  try {
    await companiesApi.delete(id);
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

export const Companies = {
  index,
  create,
  update,
  deleteCompany,
};
